import {IQACActions} from "./action"
import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const IqacSagas = function* () {
    yield all([
      takeEvery(IQACActions.GET_IQAC, getIQAC),
    ]);
  };
  
  const getIQAC = function* (data) {
    try {
      const result = yield call(() => axios.get(`${API_URL}/iqac`));
  
      yield put({
        type: IQACActions.SET_IQAC,
        payload: result.data,
      });
    } catch (error) {
      console.error("Error", error);
    }
  };