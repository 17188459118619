import { VisitingFacultyActions } from "./action";

const initialState = {
  VisitingFacultyData: [],
};

export const VisitingFacultyReducer = (state = initialState, action) => {
  switch (action.type) {
    case VisitingFacultyActions.SET_VISITING_FACULTY:
      return {
        ...state,
        VisitingFacultyData: action.payload,
      };
    default:
      return state;
  }
};
