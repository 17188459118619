import React, { useState, } from "react";
import {  useSelector } from "react-redux";
import graduation from "../../../assets/imgs/submenu/graduation.jpg";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";

function AcademicTimetable() {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const routeNames = {
    "/academic-timetable": {
      menuName: "Pedagogy",
      subMenuName: "Academic Timetable",
    },
  };
  const generatePdfLinks = (timetable) => {
    const pdfLinks = [];
    for (let year = 1; year <= 5; year++) {
      pdfLinks.push({
        year,
        oddPdf: timetable[`year_${year}_odd_pdf`],
        evenPdf: timetable[`year_${year}_even_pdf`],
      });
    }
    return pdfLinks;
  };
  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };
  // useEffect(() => {
  //   dispatch({ type: AcademictimetableActions.GET_ACADEMIC_TIMETABLE });
  // }, [dispatch]);
  const { AcademictimetableData } = useSelector(
    (state) => state?.AcademictimetableReducer
  );
  const yearLabels = [
    "1'st Year",
    "2'nd Year",
    "3'rd Year",
    "4'th Year",
    "Final Year",
  ];
  return (
    <div>
      AcademicCalendar
      <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
      <section className="section-padding">
        <div className="container">
          <h2 className="sub-title main-color mb-25 bord-thin-bottom pb-10">
            Academic Time Table
          </h2>

          <table className="rwd-table timetable-res">
            <thead className="desktop-only">
              <tr className="desktop-only1">
                <th className="border-right timetable-year abc">Year</th>
                <th className="border-right timetable-years abc">1'st year</th>
                <th className="border-right timetable-years abc">2'nd year</th>
                <th className="border-right timetable-years abc">3'rd Year</th>
                <th className="border-right timetable-years abc">4'th Year</th>
                <th className="timetable-years">Final Year</th>
              </tr>
            </thead>
            <tbody>
              {AcademictimetableData?.academic_timetable?.map((timetable) => (
                <tr key={timetable.id} className="academic-timetable-row desktop-only1">
                  <td data-th="Year" className="academic-timetable academic-timetable-row academic-calendar-responsive def">
                  {timetable.year_from} - {timetable.year_to}
                  </td>
                  {generatePdfLinks(timetable).map((links, index) => (
                    <td key={index} data-th={yearLabels[index]}className="pdf-links-cell res-cells def">
                      <a
                        className="hov-line timetable-sem res-sem"
                        href={links.oddPdf}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ODD Sem
                      </a>
                      <span className="separator">, </span>
                      <a
                        className="hov-line timetable-sem res-sem"
                        href={links.evenPdf}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        EVEN Sem
                      </a>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default AcademicTimetable;