import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../../Store/Home/action";

function ProjectLists() {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch({ type: HomePageActions.GET_HOME_STUDENTPORTFOLIO });
  // }, [dispatch]);

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_LISTPAGE_BACKGROUND_IMAGE });
  }, [dispatch]);

  const { StudentPortfolio } = useSelector((state) => state.HomePageReducer);
  const { ListpageBGImage } = useSelector((state) => state.HomePageReducer);

  const backgroundImageUrl =
    ListpageBGImage.length > 0 ? ListpageBGImage[0].image : "";

  const [activeFilter, setActiveFilter] = useState("*");
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    
    if (Array.isArray(StudentPortfolio) && StudentPortfolio.length > 0) {
      const allCategories = StudentPortfolio.reduce((acc, project) => {
        const projectCategories = project.category || "";
        if (projectCategories && !acc.includes(projectCategories)) {
          acc.push(projectCategories);
        }
        return acc;
      }, []);
      setCategories(allCategories);
    }
  }, [StudentPortfolio]);

  const handleFilterClick = (category) => {
    setActiveFilter(category);
  };

  const handleProjectDetailpage = (projectId) => {
    navigate("/ProjectDetail", {
      state: { projectId },
    });
  };

  return (
    <div>
      <main className="main-bg blog-wrap">
        <header
          className="page-header-cerv bg-img section-padding  bg-img1"
          style={{
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          data-overlay-dark="4"
        >
          <div className="container pt-100 ontop">
            <div className="text-left">
              <h2>Project List</h2>
              <div>
                <a href="/">Home</a>
                <span className="padding-rl-10">|</span>
                <span>project list</span>
              </div>
            </div>
          </div>
        </header>
      </main>
      <section className="work-stand section-padding sub-bg">
        <div className="container-xxl">
          <div className="row">
            <div className="filtering col-12 text-center">
              <div className="filter">
                <span className="text">Filter By :</span>
                <span
                  onClick={() => handleFilterClick("*")}
                  className={activeFilter === "*" ? "active" : ""}
                >
                  Show All
                </span>
                {categories.map((category, index) => (
                  <span
                    key={index}
                    onClick={() => handleFilterClick(category)}
                    className={activeFilter === category ? "active" : ""}
                  >
                    {category}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="gallery row stand-marg student-project-wrap">
            {Array.isArray(StudentPortfolio) &&
              StudentPortfolio.filter(
                (item) => activeFilter === "*" || item.category === activeFilter
              ).map((item, index) => (
                <div
                  key={index}
                  className="col-lg-3 col-md-6 items"
                  onClick={() => handleProjectDetailpage(item.id)}
                >
                  <div className="item mt-40 res-prj-list">
                    <div className="img">
                      <a className="pro-img res-project-list">
                        <img src={item.thumbnail} alt="" />
                      </a>
                    </div>
                    <div className="cont mt-20">
                      <h5 className="fz-22">
                        <a className="pro-img">{item.projectname}</a>
                      </h5>
                      <p>
                        <a className="pro-img">{item.studentname}</a>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default ProjectLists;
