import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Store/Store";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import "./assets/css/plugins/pe-icon-7-stroke.css";
import "./assets/css/plugins/fontawesome-all.min.css";
import "./assets/css/plugins/justifiedGallery.min.css";
import "./assets/css/plugins/magnific-popup.css";
import "./assets/css/plugins/YouTubePopUp.css";
import "./assets/css/plugins/swiper.min.css";
import "./assets/css/plugins/themify-icons.css";
import "./assets/css/base.css";
import "./assets/css/style.css";
import "./assets/css/plugins.css";
import GlobalStyle from "./common/GlobalStyle";
import AnimatedCursor from "react-animated-cursor";
import Cursor from "./common/Cursor";

//PAGES
import Home from "./Pages/Home";
import GalleryPage from "./Pages/Gallery";
import StudentProjectListPage from "./Pages/StudentProjects";
import ProjectListsPage from "./Pages/ProjectListsPage";
import PsgIap from "./Pages/PsgIap";
import Trust from "./Pages/Trust";
import Adminstratio from "./Pages/Adminstration";
import StatutoryCommittee from "./Pages/StatutoryCommittee";
import Factsandfigures from "./Pages/Factsandfigures";
import CoreFaculty from "./Pages/Corefaculty";
import Visitingfaculty from "./Pages/Visitingfaculty";
import Alliedfaculty from "./Pages/Alliedfaculty";
import Administrativestaff from "./Pages/Administrativestaff";
import Principalll from "./Pages/Principalll";
import Expertpanelmembers from "./Pages/Expertpanelmembers";
import Designchair from "./Pages/Designchair";
import Syllabus from "./Pages/Syllabus";
import Academiccalendar from "./Pages/Academiccalendar";
import Academictimetable from "./Pages/Academictimetable";
import Programs from "./Pages/Programs";
import LifeAtCampus from "./Pages/LifeAtCampus";
import MonthlyLectureSeriess from "./Pages/MonthlyLectureSeriess";
import Nasaindex from "./Pages/Nasaindex";
import StudyTourindex from "./Pages/StudyTour";
import Symposiumindex from "./Pages/Symposiumindex";
import SiteVistsFieldEventsIndex from "./Pages/SiteVistsFieldEventsIndex";
import ConnectWithusIndex from "./Pages/ConnectWithus";
import Iqacindex from "./Pages/Iqacindex";
import AdmissionMainmenu from "./Pages/AdmissionMainmenu";
import Workindex from "./Pages/Workindex";

function App() {
  return (
    <BrowserRouter>
      <div className="crev-agency">
        <div className="App">
          <AnimatedCursor
            color="128, 128, 128"
            innerSize={0}
            // outerSize={8}
            outerAlpha={0.2}
            innerScale={0.7}
            outerScale={6}
            animationDuration={0.01}
            showSystemCursor={true}
          />

          {/* <Cursor /> */}
          <GlobalStyle />
          <div id="smooth-content">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/Gallery" element={<GalleryPage />} />
              <Route
                exact
                path="/StudentsProjectLists"
                element={<ProjectListsPage />}
              />
              <Route
                exact
                path="/ProjectDetail"
                element={<StudentProjectListPage />}
              />
              <Route exact path="/psg-iaq" element={<PsgIap />} />
              <Route exact path="/the-trust" element={<Trust />} />
              <Route exact path="/administration" element={<Adminstratio />} />
              <Route
                exact
                path="/statutory-committee"
                element={<StatutoryCommittee />}
              />
              <Route
                exact
                path="/facts-and-figures"
                element={<Factsandfigures />}
              />
              <Route exact path="/core-faculty" element={<CoreFaculty />} />
              <Route
                exact
                path="/visiting-faculty"
                element={<Visitingfaculty />}
              />
              <Route exact path="/allied-faculty" element={<Alliedfaculty />} />
              <Route
                exact
                path="/administrative-staffs"
                element={<Administrativestaff />}
              />
              <Route exact path="/principal" element={<Principalll />} />
              <Route
                exact
                path="/expert-panel-members"
                element={<Expertpanelmembers />}
              />
              <Route exact path="/design-chair" element={<Designchair />} />
              <Route exact path="/programs" element={<Programs />} />
              <Route
                exact
                path="/academic-time-table"
                element={<Academictimetable />}
              />
              <Route
                exact
                path="/academic-calendar"
                element={<Academiccalendar />}
              />
              <Route exact path="/syllabus" element={<Syllabus />} />

              <Route exact path="/life-at-campus" element={<LifeAtCampus />} />

              <Route
                exact
                path="/monthly-lecture-series"
                element={<MonthlyLectureSeriess />}
              />
              <Route exact path="/nasa" element={<Nasaindex />} />
              <Route exact path="/symposium" element={<Symposiumindex />} />
              <Route exact path="/study-tour" element={<StudyTourindex />} />
              <Route
                exact
                path="/site-visits&field-visits"
                element={<SiteVistsFieldEventsIndex />}
              />
              <Route
                exact
                path="/ConnectWithus"
                element={<ConnectWithusIndex />}
              />
              <Route exact path="/iqac" element={<Iqacindex />} />

              <Route exact path="/admissions" element={<AdmissionMainmenu />} />
              <Route exact path="/work-with-us" element={<Workindex />} />
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
