import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import PsgLap from "../components/Submenu/Psg-iap/PsgLap";
import Cursor from "../common/Cursor";
import Loading from "../common/Loading";
import { useDispatch, useSelector } from "react-redux";
import { PSGIAQactions } from "../Store/psg-iaq/action";
import { PageOpeningAnimation } from "../common/pageOpeningAnimation";
import gsap from 'gsap';

function PsgIap() {
  document.title = "PSG-IAQ";
  const dispatch = useDispatch()
  // const backgroundColor = "black";
  const { AboutusandSections } = useSelector((state) => state.psgiaq);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch({ type: PSGIAQactions.GET_PSGIAQ_ABOUTUS_AND_SECTIONS });
  }, [dispatch]);

  useEffect(() => {
    if (AboutusandSections?.psgiaq?.length !== 0) {
      setLoading(false);
    } else {
      setLoading(false)
    }
  }, [AboutusandSections])

  //For page opening animations
  useEffect(() => {
    if (!loading) {
      const svg = document.getElementById("svg");
      const tl = gsap.timeline({ repeat: 0, yoyo: true });

      tl.to('.bluee', {
        duration: 1,
        attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
        ease: 'power2.in',
      }).to('.bluee', {
        duration: 1,
        attr: { d: 'M0 2S175 1 500 1s500 1 500 1V0H0Z' },
        ease: 'power2.out',
      });

      // Start SVG animation after the loading text is hidden
      tl.add(() => {
        gsap.to(svg, {
          duration: 0.8,
          attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
          ease: 'power2.in',
          opacity: 0, // Fade out the SVG
          onComplete: () => {
            svg.style.display = 'none'; // Hide the SVG
          },
        });
      });

      // Clean up animation on unmount
      return () => {
        tl.kill(); // Kill the animation to prevent memory leaks
      };
    }
  }, [loading]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <PageOpeningAnimation />
          {/* <Cursor /> */}
          <Header />
          {/* <Header backgroundColor={backgroundColor} /> */}
          <PsgLap />
          <Footer />
        </>
      )}
    </div>
  );
}

export default PsgIap;
