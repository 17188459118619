import { SonsandCharitiesActions } from "./action";

const initialState = {
  Charitieslink: [],
};

export const SonsandCharitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SonsandCharitiesActions.SET_SONSANDCHARITIES_SECTION:
      return {
        ...state,
        Charitieslink: action.payload,
      };
    default:
      return state;
  }
};
