import React, { useState, useEffect } from "react";
import img1 from "../../assets/imgs/serv-icons/1.png";
import graduation from "../../assets/imgs/submenu/graduation.jpg";
import { useLocation } from "react-router-dom";
import SideNavigation from "../Menu-sidenavbar/SideNavigation ";
import { IQACActions } from "../../Store/IQAC/action.js";
import { useDispatch, useSelector } from "react-redux";
import HeaderBackground from "../../common/SubpagesBGimage";

function Iqac() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("tabs-1");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  const routeNames = {
    "/iqac": {
      menuName: "Iqac",
    },
  };

  const { menuName } = location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    dispatch({ type: IQACActions.GET_IQAC });
  }, [dispatch]);

  const { IQACdata } = useSelector((state) => state?.iqacReducer);
  console.log("IQACdata", IQACdata);
  const handleTabClick = (tabs) => {
    setActiveTab(tabs);
  };

  const renderContent = () => {
    const currentSection = IQACdata?.sections?.find(
      (section, index) => `tabs-${index + 1}` === activeTab
    );

    if (currentSection) {
      const { contents } = currentSection;

      if (contents.length > 1) {
        return (
          <div className="tab-content current" id={activeTab}>
            <div className="item">
              <div className="sub-bg">
                <div className="icon-img-60 mb-40">
                  <img src={img1} alt="" />
                </div>
                <div className="text">
                  <ul className="rest list-arrow mt-30 bord-thin-top">
                    <h6 className="Components-title">
                      {currentSection.section.section_name}
                    </h6>
                    {contents.map((content, i) => (
                      <li key={i}>
                        <span className="icon iqac-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2.2em"
                            height="2.2em"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              cx="12.1"
                              cy="12.1"
                              r="1"
                              fill="none"
                              stroke="#84e3c6"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                          </svg>
                        </span>
                        <h6 className="inline fw-400 fz-16 iqac-content">
                          {content.iqac_content}
                        </h6>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (contents.length === 1) {
        return (
          <div className="tab-content current" id={activeTab}>
            <div className="item">
              <div className="sub-bg">
                <div className="icon-img-60 mb-40">
                  <img src={img1} alt="" />
                </div>
                <div className="text">
                  <div className="text bord-thin-top achieve-pd">
                    <h6 className="Components-title">
                      {currentSection.section.section_name}
                    </h6>
                    <p>{contents[0].iqac_content}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return null;
  };

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        handleMenuToggle={handleMenuToggle}
      />
      <section className="services-tab section-padding mb-80">
        <div className="container">
          <div className="cal-action pb-10 mb-40 bord-thin-bottom">
            <h6 className="sub-title mb-15 main-color">IQAC</h6>
          </div>
          <div className="row lg-marg" id="tabs">
            <div className="d-lg-none col-12">
              <div className="serv-tab-link tab-links full-width pt-40">
                <div className="row justify-content-end">
                  <div className="col-lg-12">
                    <ul className="rest iqac-responsive">
                      {IQACdata?.sections?.map((section, index) => (
                        <li
                          key={index}
                          className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                            activeTab === `tabs-${index + 1}` ? "current" : ""
                          }`}
                          data-tab={`tabs-${index + 1}`}
                          onClick={() => handleTabClick(`tabs-${index + 1}`)}
                        >
                          <h5 className="iqac-texts">
                            <span className="fz-18 opacity-7 mr-15 ">{`0${
                              index + 1
                            }`}</span>
                            {section.section.section_name}
                          </h5>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 d-none d-lg-block">
              <div className="serv-tab-link tab-links full-width pt-40">
                <div className="row justify-content-end">
                  <div className="col-lg-12">
                    <ul className="rest">
                      {IQACdata?.sections?.map((section, index) => (
                        <li
                          key={index}
                          className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                            activeTab === `tabs-${index + 1}` ? "current" : ""
                          }`}
                          onClick={() => handleTabClick(`tabs-${index + 1}`)}
                        >
                          <div className="u-line linee">
                            <span className="icon ti-arrow-circle-right iqac-right"></span>
                            <span className="text fz-20 psg-margin">
                              {section.section.section_name}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-12">
              <div className="md-mb80">{renderContent()}</div>
            </div>
          </div>
        </div>
      </section>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Iqac;
