import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../Store/Home/action";
import { HomePageReducer } from "../Store/Home/reducer";

function MarqueeText() {
  const dispatch = useDispatch();

  const { HeaderScrollText } = useSelector((state) => state.HomePageReducer);

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_TOPBAR_SCROLL_TEXT });
  }, [dispatch]);
  const marqueeRef = useRef(null);

  const handleMouseEnter = () => {
    if (marqueeRef.current) {
      marqueeRef.current.stop();
    }
  };

  const handleMouseLeave = () => {
    if (marqueeRef.current) {
      marqueeRef.current.start();
    }
  };

  return (
    <div>
      <div className="text-wrapper-marque col-md-9">
        <marquee
          ref={marqueeRef}
          className="marquee-scroll"
          scrollamount="7"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {HeaderScrollText.map((item, index) => (
            <span key={index} className="marquee-scroll-item">
              <span style={{ marginRight: "5px" }}>{item.title}</span>{" "}
              {/* Add margin-right style */}
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.link_text}
              </a>
            </span>
          ))}
        </marquee>
      </div>

      <div className="code-wrap col-md-3 text-right">
        <p className="static-text">
          TNEA Counselling code: <span>2379</span>
        </p>
      </div>
    </div>
  );
}
export default MarqueeText;
