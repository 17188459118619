import { WorkWithUsActions } from "./action";
import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const WorkWithUsSagas = function* () {
  yield all([takeEvery(WorkWithUsActions.GET_WORK_WITH_US, getWorkWithUs)]);
};

const getWorkWithUs = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/work-with-us`));

    yield put({
      type: WorkWithUsActions.SET_WORK_WITH_US,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
