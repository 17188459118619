import { DesignchairActions } from "./action";

const initialState = {
  DesignchairDetails: [],
};

export const DesignchairReducer = (state = initialState, action) => {
  switch (action.type) {
    case DesignchairActions.SET_DESIGNCHAIR_SECTION_DETAILS:
      return {
        ...state,
        DesignchairDetails: action.payload,
      };
    default:
      return state;
  }
};
