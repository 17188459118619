import React, { useState, useEffect } from 'react'
import Footer from "../components/Layout/Footer";
import Cursor from "../common/Cursor";
import Loading from "../common/Loading";
import DesignChair from '../components/Submenu/DesignChair/DesignChair';
import Header from '../components/Layout/Header';
import { DesignchairActions } from "../Store/design-chair/action";
import { useDispatch, useSelector } from 'react-redux'
import { PageOpeningAnimation } from '../common/pageOpeningAnimation';
import gsap from 'gsap';

function Designchair() {
  document.title = "Design Chair";
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch({ type: DesignchairActions.GET_DESIGNCHAIR_SECTION_DETAILS });
  }, []);

  const { DesignchairDetails } = useSelector(
    (state) => state?.DesignchairReducer
  );

  useEffect(() => {
    if (DesignchairDetails?.length !== 0) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [DesignchairDetails])

  //for page opening animations
  useEffect(() => {
    if (!loading) {
      const svg = document.getElementById("svg");
      const tl = gsap.timeline({ repeat: 0, yoyo: true });

      tl.to('.bluee', {
        duration: 1,
        attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
        ease: 'power2.in',
      }).to('.bluee', {
        duration: 1,
        attr: { d: 'M0 2S175 1 500 1s500 1 500 1V0H0Z' },
        ease: 'power2.out',
      });

      // Start SVG animation after the loading text is hidden
      tl.add(() => {
        gsap.to(svg, {
          duration: 0.8,
          attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
          ease: 'power2.in',
        });
      });

      // Clean up animation on unmount
      return () => {
        tl.kill(); // Kill the animation to prevent memory leaks
      };
    }
  }, [loading]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <PageOpeningAnimation />
          {/* <Cursor /> */}
          <Header />
          <DesignChair />
          <Footer />
        </>
      )}
    </div>
  )
}

export default Designchair