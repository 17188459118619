import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import TheTrust from "../components/Submenu/Trust/TheTrust";
import Cursor from "../common/Cursor";
import Loading from "../common/Loading";
import Footer from "../components/Layout/Footer"
import { useDispatch, useSelector } from 'react-redux'
import { TheTrustAction } from "../Store/The Trust/action";
import { PageOpeningAnimation } from "../common/pageOpeningAnimation";
import gsap from 'gsap';

function Trust() {
  document.title = "TheTrust-PSG-IAP";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) {
      // Scroll to the top of the page when loading is complete
      const scrollTimeout = setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);

      return () => clearTimeout(scrollTimeout);
    }
  }, [loading]);

  useEffect(() => {
    dispatch({ type: TheTrustAction.GET_THE_TRUST_INFO_MSG });
  }, [dispatch]);

  const { TheTrustInfoandMsg } = useSelector((state) => state?.TheTrustReducer);

  useEffect(() => {
    if (TheTrustInfoandMsg?.length !== 0) {
      setLoading(false)
    }else{
      setLoading(false)
    }
  }, [TheTrustInfoandMsg])

  //for page opening animations
  useEffect(() => {
    if (!loading) {
      const svg = document.getElementById("svg");
      const tl = gsap.timeline({ repeat: 0, yoyo: true });

      tl.to('.bluee', {
        duration: 1,
        attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
        ease: 'power2.in',
      }).to('.bluee', {
        duration: 1,
        attr: { d: 'M0 2S175 1 500 1s500 1 500 1V0H0Z' },
        ease: 'power2.out',
      });

      // Start SVG animation after the loading text is hidden
      tl.add(() => {
        gsap.to(svg, {
          duration: 0.8,
          attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
          ease: 'power2.in',
        });
      });

      // Clean up animation on unmount
      return () => {
        tl.kill(); // Kill the animation to prevent memory leaks
      };
    }
  }, [loading]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <PageOpeningAnimation />
          {/* <Cursor /> */}
          <Header />
          <TheTrust />
          <Footer />
        </>
      )}
    </div>
  );
}
export default Trust;
