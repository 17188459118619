import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import graduation from "../../../assets/imgs/submenu/graduation.jpg";
import avatar from "../../../assets/imgs/core-faculty/avatarman2n.webp";
import { ExpertPanelMembersActions } from "../../../Store/expert-panel-memebers/action";
import HeaderBackground from "../../../common/SubpagesBGimage";

function ExpertPanelMembers() {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoverPosition, setHoverPosition] = useState({
    top: "50px",
    left: "50px",
  });
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  useEffect(() => {
    dispatch({
      type: ExpertPanelMembersActions.GET_EXPERTPANEL_MEMBERS_MENTORS_EXPERT_PANEL,
    });
  }, [dispatch]);

  const { ChiefAdvisorandMemberofGoverningCouncil, MentorsCumExpertPanel } =
    useSelector((state) => state?.ExpertPanelMembersReducer);

  const routeNames = {
    "/expert-panel-members": {
      menuName: "The People",
      subMenuName: "Expert Panel Members",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const handleMouseMove = (e) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setHoverPosition({ top: `${y}px`, left: `${x}px` });
  };

  const [showChiefAdvisorDescriptions, setShowChiefAdvisorDescriptions] =
    useState({});
  const [showMentorsDescriptions, setShowMentorsDescriptions] = useState({});

  const toggleChiefAdvisorDescription = (id) => {
    setShowChiefAdvisorDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const toggleMentorsDescription = (id) => {
    setShowMentorsDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const findMemberById = (id, list) => {
    return list.find((member) => member.id === Number(id));
  };

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <div className="section-padding">
        <div className="container">
          <div className="bord bord-thin-bottom d-flex align-items-center">
            <h6 className="sub-title main-color mb-25">Expert Panel Members</h6>
          </div>

          {/* Chief Advisor and Member of Governing Council */}
          <h4 className="mt-25 fw-500 mb-30 expert-txtss">
            Chief Advisor and Member of Governing Council
          </h4>
          <div className="row">
            {ChiefAdvisorandMemberofGoverningCouncil.map((member) => (
              <React.Fragment key={member.id}>
                <div
                  className="col-md-3 mb-10 core-card"
                  onClick={() => toggleChiefAdvisorDescription(member.id)}
                  onMouseMove={handleMouseMove}
                >
                  <div
                    className="card-4 card-container"
                    data-tooltip-tit="More"
                  >
                    <div className="description content-3">
                      <img
                        src={member.image || avatar}
                        alt={member.name}
                        className="core-faculty"
                      />
                      <h6 className="main-color fw-500 core-texts">
                        {member.name}
                      </h6>
                      <p className="fw-400 core-faculty-text">
                        {member.designation}
                      </p>
                      <div
                        className="hover-text"
                        style={{
                          top: hoverPosition.top,
                          left: hoverPosition.left,
                        }}
                      >
                        MORE
                      </div>
                    </div>
                  </div>
                </div>
                {showChiefAdvisorDescriptions[member.id] && (
                  <div className="col-md-12">
                    <div className="card-description-4 mb-30 visible">
                      <p>{member.description}</p>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>

          {/* Mentors Cum Expert Panel */}
          <div className="container section-padding">
            <h4 className="fw-500 mb-30 mt-30">Mentors Cum Expert Panel</h4>
            <div className="row">
              {MentorsCumExpertPanel.map((member) => (
                <React.Fragment key={member.id}>
                  <div
                    className="col-md-3 mb-10 core-card"
                    onClick={() => toggleMentorsDescription(member.id)}
                    onMouseMove={handleMouseMove}
                  >
                    <div
                      className="card-4 card-container"
                      data-tooltip-tit="More"
                    >
                      <div className="description content-3">
                        <img
                          src={member.image || avatar}
                          alt={member.name}
                          className="core-faculty"
                        />
                        <div className="card-content-4">
                          <h6 className="main-color fw-500 core-texts">
                            {member.name}
                          </h6>
                          <p className="fw-400">{member.designation}</p>
                        </div>
                        <div
                          className="hover-text"
                          style={{
                            top: hoverPosition.top,
                            left: hoverPosition.left,
                          }}
                        >
                          MORE
                        </div>
                      </div>
                    </div>
                  </div>
                  {showMentorsDescriptions[member.id] && (
                    <div className="col-md-12">
                      <div className="card-description-4 mb-30 visible">
                        <p>{member.description}</p>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default ExpertPanelMembers;
