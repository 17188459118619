import { CorefacultysActions } from "./action";

const initialState = {
  CoreFacultyDetails: [],
};

export const CorefacultysReducer = (state = initialState, action) => {
  switch (action.type) {
    case CorefacultysActions.SET_COREFACULTY_SECTION:
      return {
        ...state,
        CoreFacultyDetails: action.payload,
      };
    default:
      return state;
  }
};
