import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import graduation from "../../../assets/imgs/submenu/graduation.jpg";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import { AcademicCalendarActions } from "../../../Store/Academic Calendar/action";
import HeaderBackground from "../../../common/SubpagesBGimage";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const AcademicCalendar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const routeNames = {
    "/academic-timetable": {
      menuName: "Pedagogy",
      subMenuName: "Academic Timetable",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };
  const [today, setToday] = useState(new Date());
  const [month, setMonth] = useState(today.getMonth());
  const [year, setYear] = useState(today.getFullYear());
  const [days, setDays] = useState([]);
  const [eventsArr, setEventsArr] = useState([]);
  const [activeDay, setActiveDay] = useState(null);
  const [newEvent, setNewEvent] = useState({
    title: "",
    timeFrom: "",
    timeTo: "",
  });
  const [dateInput, setDateInput] = useState("");
  const [showAddEvent, setShowAddEvent] = useState(false);

  useEffect(() => {
    // Load events from localStorage
    const savedEvents = localStorage.getItem("events");
    if (savedEvents) {
      setEventsArr(JSON.parse(savedEvents));
    }
  }, []);

  useEffect(() => {
    // Update calendar when month/year changes
    updateCalendar();
  }, [month, year]);

  const updateCalendar = () => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const prevLastDay = new Date(year, month, 0);
    const prevDays = prevLastDay.getDate();
    const lastDate = lastDay.getDate();
    const day = firstDay.getDay();
    const nextDays = 7 - lastDay.getDay() - 1;

    let days = [];
    for (let x = day; x > 0; x--) {
      days.push({ type: "prev-date", date: prevDays - x + 1 });
    }
    for (let i = 1; i <= lastDate; i++) {
      const event = eventsArr.some(
        (e) => e.day === i && e.month === month + 1 && e.year === year
      );
      const isToday =
        i === today.getDate() &&
        year === today.getFullYear() &&
        month === today.getMonth();
      days.push({
        type: isToday
          ? event
            ? "today event"
            : "today"
          : event
          ? "event"
          : "",
        date: i,
      });
    }
    for (let j = 1; j <= nextDays; j++) {
      days.push({ type: "next-date", date: j });
    }

    // Set calendar days
    setDays(days);
  };

  const handleDayClick = (day) => {
    if (day.type === "prev-date") {
      setMonth((prevMonth) => prevMonth - 1);
      if (month < 0) {
        setMonth(11);
        setYear((prevYear) => prevYear - 1);
      }
    } else if (day.type === "next-date") {
      setMonth((prevMonth) => prevMonth + 1);
      if (month > 11) {
        setMonth(0);
        setYear((prevYear) => prevYear + 1);
      }
    } else {
      const selectedDate = new Date(year, month, day.date)
        .toISOString()
        .split("T")[0];
      const eventsForDate = AcademicCalendarData.filter((event) => {
        const eventDate = formatDateString(event.date)
          .toISOString()
          .split("T")[0];
        return eventDate === selectedDate;
      });
      setActiveDay(day.date);
      setEventsArr(eventsForDate.length > 0 ? eventsForDate : []);
    }
  };

  const handleAddEvent = () => {
    if (!newEvent.title || !newEvent.timeFrom || !newEvent.timeTo) {
      alert("Please fill all the fields");
      return;
    }

    // Check time format
    const timeFromArr = newEvent.timeFrom.split(":");
    const timeToArr = newEvent.timeTo.split(":");
    if (
      timeFromArr.length !== 2 ||
      timeToArr.length !== 2 ||
      timeFromArr[0] > 23 ||
      timeFromArr[1] > 59 ||
      timeToArr[0] > 23 ||
      timeToArr[1] > 59
    ) {
      alert("Invalid Time Format");
      return;
    }

    const timeFrom = convertTime(newEvent.timeFrom);
    const timeTo = convertTime(newEvent.timeTo);

    // Check if event already exists
    let eventExists = eventsArr.some(
      (e) =>
        e.day === activeDay &&
        e.month === month + 1 &&
        e.year === year &&
        e.events.some((ev) => ev.title === newEvent.title)
    );

    if (eventExists) {
      alert("Event already added");
      return;
    }

    const event = {
      day: activeDay,
      month: month + 1,
      year: year,
      events: [{ title: newEvent.title, time: `${timeFrom} - ${timeTo}` }],
    };

    setEventsArr((prevEvents) => {
      const updatedEvents = [...prevEvents];
      const existingEventIndex = updatedEvents.findIndex(
        (e) => e.day === activeDay && e.month === month + 1 && e.year === year
      );

      if (existingEventIndex > -1) {
        updatedEvents[existingEventIndex].events.push(event.events[0]);
      } else {
        updatedEvents.push(event);
      }

      localStorage.setItem("events", JSON.stringify(updatedEvents));
      return updatedEvents;
    });

    setNewEvent({ title: "", timeFrom: "", timeTo: "" });
  };

  const convertTime = (time) => {
    let [hour, minute] = time.split(":");
    let timeFormat = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    return `${hour}:${minute} ${timeFormat}`;
  };

  const gotoDate = () => {
    const [month, year] = dateInput.split("/");
    const newMonth = parseInt(month, 10) - 1;
    setMonth(newMonth);
    setYear(parseInt(year, 10));
  };

  // useEffect(() => {
  //   dispatch({ type: AcademicCalendarActions.GET_ACADEMIC_CALENDAR });
  // }, [dispatch]);

  const { AcademicCalendarData } = useSelector(
    (state) => state?.AcademicCalendarReducer
  );

  const formatDateString = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return new Date(year, month - 1, day);
  };

  return (
    <main>
      <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
      <section className="section-padding academic-bg">
        <div className="ca-main">
          <div className="container">
            <div className="ca-flex">
              <div className="left">
                <div className="calendar">
                  <div className="month">
                    <i
                      className="fas fa-angle-left prev"
                      onClick={() => setMonth((prevMonth) => prevMonth - 1)}
                    ></i>
                    <div className="date">
                      {months[month]} {year}
                    </div>
                    <i
                      className="fas fa-angle-right next"
                      onClick={() => setMonth((prevMonth) => prevMonth + 1)}
                    ></i>
                  </div>
                  <div className="weekdays">
                    <div>Sun</div>
                    <div>Mon</div>
                    <div>Tue</div>
                    <div>Wed</div>
                    <div>Thu</div>
                    <div>Fri</div>
                    <div>Sat</div>
                  </div>
                  <div className="days">
                    {days.map((day, index) => (
                      <div
                        className={`day ${day.type} ${
                          day.date === activeDay &&
                          day.type !== "prev-date" &&
                          day.type !== "next-date"
                            ? "active"
                            : ""
                        }`}
                        key={index}
                        onClick={() => handleDayClick(day)}
                      >
                        {day.date}
                      </div>
                    ))}
                  </div>

                  <div className="goto-today">
                    <div className="goto">
                      <input
                        type="text"
                        placeholder="mm/yyyy"
                        className="date-input"
                        value={dateInput}
                        onChange={(e) => setDateInput(e.target.value)}
                      />
                      <button className="goto-btn" onClick={gotoDate}>
                        Go
                      </button>
                    </div>
                    <button
                      className="today-btn"
                      onClick={() => {
                        const today = new Date();
                        setMonth(today.getMonth());
                        setYear(today.getFullYear());
                      }}
                    >
                      Today
                    </button>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="today-date">
                  <div className="event-day">
                    {new Date(year, month, activeDay).toLocaleDateString(
                      "en-US",
                      { weekday: "short" }
                    )}
                  </div>
                  <div className="event-date">
                    {activeDay} {months[month]} {year}
                  </div>
                </div>
                <div className="events">
                  {eventsArr.length > 0 ? (
                    eventsArr.map((event) => (
                      <div key={event.id} className="event">
                        <div className="title">
                          <i className="fas fa-circle"></i>
                          <h3 className="event-title">{event.event}</h3>
                        </div>
                        <div className="event-time">
                          <span className="event-time">
                            {new Date(event.created_at).toLocaleTimeString()}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-event">
                      <h3>No Events</h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add event modal */}
        <div className={`add-event-wrapper ${showAddEvent ? "active" : ""}`}>
          <button className="close" onClick={() => setShowAddEvent(false)}>
            Close
          </button>
          <input
            type="text"
            className="event-name"
            placeholder="Event Title"
            value={newEvent.title}
            onChange={(e) =>
              setNewEvent({ ...newEvent, title: e.target.value })
            }
          />
          <input
            type="time"
            className="event-time"
            placeholder="Start Time"
            value={newEvent.timeFrom}
            onChange={(e) =>
              setNewEvent({ ...newEvent, timeFrom: e.target.value })
            }
          />
          <input
            type="time"
            className="event-time"
            placeholder="End Time"
            value={newEvent.timeTo}
            onChange={(e) =>
              setNewEvent({ ...newEvent, timeTo: e.target.value })
            }
          />
          <button className="save" onClick={handleAddEvent}>
            Save
          </button>
        </div>
      </section>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </main>
  );
};

export default AcademicCalendar;
