import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { SonsandCharitiesActions } from "../Store/PsgSonsCharities/action";
import $ from "jquery";

const Search = ({ color, routes, navigate, handleMenuToggle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [charityLink, setCharityLink] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: SonsandCharitiesActions.GET_SONSANDCHARITIES_SECTION });
  }, [dispatch]);

  const { Charitieslink } = useSelector(
    (state) => state.SonsandCharitiesReducer
  );

  useEffect(() => {
    if (Charitieslink?.length) {
      setCharityLink(Charitieslink[0]?.link);
    }
  }, [Charitieslink]);

  useEffect(() => {
    // jQuery effects
    $(".navbar .search-form").on("click", ".search-icon", function () {
      $(".navbar .search-form").toggleClass("open");

      if ($(".navbar .search-form").hasClass("open")) {
        $(".search-form .close-search").slideDown();
      } else {
        $(".search-form .close-search").slideUp();
      }
    });

    $(".navbar").on("click", ".navbar-toggler", function () {
      $(".navbar .navbar-collapse").toggleClass("show");
    });

    $(window).on("scroll", function () {
      var bodyScroll = window.scrollY,
        navbar = $(".navbar"),
        logo = $(".navbar.change .logo > img");

      if (bodyScroll > 300) {
        navbar.addClass("nav-scroll");
        logo.attr("src", "assets/imgs/logo-dark.png");
      } else {
        navbar.removeClass("nav-scroll");
        logo.attr("src", "assets/imgs/logo-light.png");
      }
    });

    // Clean up jQuery events
    return () => {
      $(".navbar .search-form").off("click", ".search-icon");
      $(".navbar").off("click", ".navbar-toggler");
      $(window).off("scroll");
    };
  }, []); // Empty dependency array ensures this runs only once

  const handleSearchToggle = () => {
    setIsOpen((prevState) => !prevState);
    if (isOpen) {
      setSearchTerm("");
      setFilteredRoutes([]);
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const uniqueRoutes = Array.from(
      new Set([
        ...routes.map((route) => JSON.stringify(route)),
        JSON.stringify({ name: "PSG & Sons’ Charities", path: charityLink }),
      ])
    ).map((route) => JSON.parse(route));

    if (term) {
      const results = uniqueRoutes.filter(
        (route) =>
          route.name.toLowerCase().includes(term.toLowerCase()) ||
          (route.path && route.path.toLowerCase().includes(term.toLowerCase()))
      );
      setFilteredRoutes(results);
    } else {
      setFilteredRoutes([]);
    }
  };

  const handlePageSelect = (path) => {
    if (path.startsWith("http")) {
      window.open(path, "_blank");
    } else {
      navigate(path);
    }
    setSearchTerm("");
    setFilteredRoutes([]);
    handleSearchToggle();
    handleMenuToggle();
  };

  return (
    <div className={`search-form ml-auto ${isOpen ? "open" : ""}`}>
      {isOpen && (
        <div className="form-group">
          <input
            type="text"
            name="search"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
          <button onClick={handleSearchToggle} className="search-button">
            <span className="pe-7s-close"></span>
          </button>
          {filteredRoutes.length > 0 && (
            <div className="search-dropdown">
              <ul className="search-results">
                {filteredRoutes.map((route, index) => (
                  <li
                    key={index}
                    onClick={() => handlePageSelect(route.path)}
                    className="search-item"
                  >
                    {route.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
      {!isOpen && (
        <div className="search-icon" style={{ color }}>
          <span className="pe-7s-search" onClick={handleSearchToggle}></span>
        </div>
      )}
    </div>
  );
};

Search.propTypes = {
  color: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  navigate: PropTypes.func.isRequired,
  handleMenuToggle: PropTypes.func.isRequired,
};

export default Search;
