import React, { useState, useEffect } from "react";
import StudentsProjectLists from "../components/Student Portfolio/Student-Detailpage/ProjectLists";
import Footer from "../components/Layout/Footer";
import Loading from "../common/Loading";
import Cursor from "../common/Cursor";
import Header from "../components/Layout/Header";
import { useDispatch, useSelector } from 'react-redux'
import { HomePageActions } from "../Store/Home/action";
import { PageOpeningAnimation } from "../common/pageOpeningAnimation";
import gsap from 'gsap';

function ProjectListsPage() {
  document.title = "ProjectListsPage";
  const textColor = "black";
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_STUDENTPORTFOLIO });
  }, []);

  const { StudentPortfolio } = useSelector((state) => state.HomePageReducer);

  useEffect(() => {
    if (StudentPortfolio?.length !== 0) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [StudentPortfolio])

  //for page opening animations
  useEffect(() => {
    if (!loading) {
      const svg = document.getElementById("svg");
      const tl = gsap.timeline({ repeat: 0, yoyo: true });

      tl.to('.bluee', {
        duration: 1,
        attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
        ease: 'power2.in',
      }).to('.bluee', {
        duration: 1,
        attr: { d: 'M0 2S175 1 500 1s500 1 500 1V0H0Z' },
        ease: 'power2.out',
      });

      // Start SVG animation after the loading text is hidden
      tl.add(() => {
        gsap.to(svg, {
          duration: 0.8,
          attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
          ease: 'power2.in',
        });
      });

      // Clean up animation on unmount
      return () => {
        tl.kill(); // Kill the animation to prevent memory leaks
      };
    }
  }, [loading]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <PageOpeningAnimation />
          <Header isInProject={true} />
          {/* <Cursor /> */}
          {/* <Header Color={textColor} isInGallery={true} /> */}
          <StudentsProjectLists />
          <Footer />
        </div>
      )}
    </div>
  );
}

export default ProjectListsPage;
