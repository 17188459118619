import React, { useEffect } from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Container } from "react-bootstrap";
// import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import { useDispatch, useSelector } from "react-redux";
// import { HomePageActions } from "../../../Store/Home/action";

const Gallery = () => {
  // const location = useLocation();
  // const dispatch = useDispatch();
  // const { state } = location;

  // useEffect(() => {
  //   window.scrollTo(0, 0);

  //   if (state?.id) {
  //     const payload = {
  //       id: state.id,
  //     };

  //     dispatch({
  //       type: HomePageActions.GET_DETAILPAGE,
  //       payload: payload,
  //     });
  //   }
  // }, [dispatch, state?.id]);

  const { DetailPage } = useSelector((state) => state.HomePageReducer);

  useEffect(() => {
    if (DetailPage) {
      Fancybox.bind('[data-fancybox="gallery"]');
    }
  }, [DetailPage]);

  const title = DetailPage?.title || "";
  const description = DetailPage?.description || "";
  const images = DetailPage?.images || [];

  return (
    <Container>
      <section className="main-grid">
        <div className="gallery-head">
          <h1 className="text-center h1 pb-40 gallery-heading">{title}</h1>
          <p className="mb-30 ml-20 mr-15">{description}</p>
        </div>

        <div className="container">
          {images?.length > 0 && (
            images.map((image, index) => (
              <div className="card" key={index}>
                <div className="card-image">
                  <a
                    href={image.image}
                    data-fancybox="gallery"
                    data-caption={image.content || "Image Gallery"}
                  >
                    <img src={image.image} alt="Gallery" />
                  </a>
                </div>
              </div>
            ))
          )
            // : (
            //   <p>No images available.</p>
            // )
          }
        </div>
      </section>
    </Container>
  );
};

export default Gallery;
