import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { FooterActions } from "../../Store/Footer/action";

gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    gsap.set(".footer-container", { yPercent: -50 });
    const uncover = gsap
      .timeline({ paused: true })
      .to(".footer-container", { yPercent: 0, ease: "none" });

    ScrollTrigger.create({
      trigger: "main",
      start: "bottom bottom",
      end: "+=50%",
      animation: uncover,
      scrub: true,
    });

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    gsap.to(".footer-container", { yPercent: -scrollY });
  };

  useEffect(() => {
    dispatch({ type: FooterActions.GET_FOOTER_DETAILS });
  }, [dispatch]);

  const { Footerdata } = useSelector((state) => state.FooterReducer);

  return (
    <div className="footer-common">
      <footer
        className="clean-footer crev bg-img"
        data-background="assets/imgs/background/3.jpg"
        data-overlay-dark="10"
      >
        <div className="container pb-0 pt-70 section-padding ontop">
          <div className="row row-footer">
            <div className="col-lg-4 col-md-12">
              <h6 className="sub-title mb-30">About Us</h6>
              <p className="opacity-8 footer-text">
                {Footerdata["About-us"] && Footerdata["About-us"][0]?.text}
              </p>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12">
              <div className="column">
                <h6 className="sub-title mb-30 footer-subtitle1">
                  Useful Links
                </h6>
                <ul className="rest fz-14">
                  {Footerdata.links &&
                    Footerdata.links.map((link, index) => (
                      <li key={index} className="mb-15 opacity-8">
                        <a
                          href={link.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link.pagename}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12">
              <div className="column">
                <h6 className="sub-title mb-30 footer-subtitle">Downloads</h6>
                <ul className="rest fz-14">
                  {Footerdata.downloads &&
                    Footerdata.downloads.map((download, index) => (
                      <li key={index} className="mb-15 opacity-8">
                        <a
                          href={download.pdf}
                          target="_blank"
                          rel="noopener noreferrer"
                          // onClick={(e) => {
                          //   // Optional: You can still include the preventDefault to handle specific logic
                          //   // e.preventDefault();
                          //   // window.location.href = download.pdf;
                          // }}
                        >
                          {download.pdf_name}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="column">
                {Footerdata.contact &&
                  Footerdata.contact.map((contact, index) => (
                    <div key={index} className="mb-10">
                      <h6 className="sub-title mb-30 footer-subtitle">
                        Contact
                      </h6>
                      <p className="opacity-8 footer-text">{contact.address}</p>
                      <a className="opacity-8" href={`mailto:${contact.mail}`}>
                        <span>{contact.mail}</span>
                      </a>

                      <div className="contact-numbers">
                        <a
                          className="opacity-8"
                          href={`tel:${contact.contact_no_1}`}
                        >
                          <span className="num-footer">
                            {contact.contact_no_1}
                          </span>
                        </a>
                        {" | "}
                        <a
                          className="opacity-8"
                          href={`tel:${contact.contact_no_2}`}
                        >
                          <span className="num-footer">
                            {contact.contact_no_2}
                          </span>
                        </a>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center pt-30 pb-30 mt-80 bord-thin-top">
            <div>
              <p className="fz-14">
                Copyright © {new Date().getFullYear()} PSG IAP. All rights
                reserved. Design :{" "}
                <span>
                  <a
                    href="https://mindmade.in/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    MindMade
                  </a>
                </span>
              </p>
            </div>
            <div className="ml-auto">
              <ul className="rest d-flex align-items-center">
                {Footerdata.Social_links &&
                  Footerdata.Social_links.map((social, index) => (
                    <React.Fragment key={index}>
                      {social.facebook && (
                        <li className="hover-this cursor-pointer hover-anim">
                          <motion.a
                            href={social.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                            whileHover={{ scale: 2.1 }}
                          >
                            <i className="fab fa-facebook-f" />
                          </motion.a>
                        </li>
                      )}
                      {social.linkedin && (
                        <li className="hover-this cursor-pointer ml-30">
                          <motion.a
                            href={social.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                            whileHover={{ scale: 2.1 }}
                          >
                            <i className="fab fa-linkedin-in" />
                          </motion.a>
                        </li>
                      )}
                      {social.instagram && (
                        <li className="hover-this cursor-pointer ml-30">
                          <motion.a
                            href={social.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                            whileHover={{ scale: 2.1 }}
                          >
                            <i className="fab fa-instagram" />
                          </motion.a>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
