import { LearningEventsActions } from "./action";

const initialState = {
  MonthlylectureseriesData: [],
  StudyTourData: [],
  SiteVisitsAndFieldVisitsData: [],
  NasaData: [],
  SymposiumData: [],
};

export const LearningEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LearningEventsActions.SET_MONTHLY_LECTURE_SERIES:
      return {
        ...state,
        MonthlylectureseriesData: action.payload,
      };

    case LearningEventsActions.SET_STUDY_TOUR:
      return {
        ...state,
        StudyTourData: action.payload,
      };
    case LearningEventsActions.SET_SITEVISITS_FIELDVISITS:
      return {
        ...state,
        SiteVisitsAndFieldVisitsData: action.payload,
      };
    case LearningEventsActions.SET_NASA:
      return {
        ...state,
        NasaData: action.payload,
      };
    case LearningEventsActions.SET_SYMPOSIUM:
      return {
        ...state,
        SymposiumData: action.payload,
      };

    default:
      return state;
  }
};
