export const AdministrationAction = {
  GET_ADMINISTRATION_ORGANIZATION: "GET_ADMINISTRATION_ORGANIZATION",
  SET_ADMINISTRATION_ORGANIZATION: "SET_ADMINISTRATION_ORGANIZATION",

  GET_GOVERNINGCOUNCILMEMBERS: "GET_GOVERNINGCOUNCILMEMBERS",
  SET_GOVERNINGCOUNCILMEMBERS: "SET_GOVERNINGCOUNCILMEMBERS",

  GET_ADMINISTRATION_SECRETARYMESSAGE: "GET_ADMINISTRATION_SECRETARYMESSAGE",
  SET_ADMINISTRATION_SECRETARYMESSAGE: "SET_ADMINISTRATION_SECRETARYMESSAGE",
};
