import { ProgramsActions } from "./action";

const initialState = {
  ProgramsData: {
    pedagogy: [],
    our_programs: [],
  }
};

export const ProgramsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProgramsActions.SET_PROGRAMS:
      return {
        ...state,
        ProgramsData: action.payload,
      };
    default:
      return state;
  }
};
