import { AcademictimetableActions } from "./action";
import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const AcademictimetableSagas = function* () {
  yield all([
    takeEvery(
      AcademictimetableActions.GET_ACADEMIC_TIMETABLE,
      getAcademictimetableInfo
    ),
  ]);
};

const getAcademictimetableInfo = function* (data) {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/academic_timetable`)
    );

    yield put({
      type: AcademictimetableActions.SET_ACADEMIC_TIMETABLE,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
