// GlobalStyle.js
import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&display=swap');

  body {
    /* Optionally, apply the fonts to the entire body */
    font-family: 'Poppins', sans-serif;
  }

  /* Define any other global styles here */
`;

export default GlobalStyle;
