import { AdministrationAction } from "./action";

const initialState = {
  OrganizationSchedule: [],
  GoverningCouncil: [],
  SecretaryInfo: [],
};

export const AdministrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case AdministrationAction.SET_GOVERNINGCOUNCILMEMBERS:
      return {
        ...state,
        GoverningCouncil: action.payload,
      };

    case AdministrationAction.SET_ADMINISTRATION_SECRETARYMESSAGE:
      return {
        ...state,
        SecretaryInfo: action.payload,
      };

    case AdministrationAction.SET_ADMINISTRATION_ORGANIZATION:
      return {
        ...state,
        OrganizationSchedule: action.payload,
      };

    default:
      return state;
  }
};
