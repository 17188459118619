import React, { useEffect, useState } from "react";
import graduation from "../../../assets/imgs/submenu/graduation.jpg";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { LearningEventsActions } from "../../../Store/Learning Events/action";
import { useDispatch, useSelector } from "react-redux";
import HeaderBackground from "../../../common/SubpagesBGimage";

const Monthlylecture = () => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const routeNames = {
    "/monthly-lecture-series": {
      menuName: "Learning Events",
      subMenuName: "Monthly lecture series",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    dispatch({ type: LearningEventsActions.GET_MONTHLY_LECTURE_SERIES });
  }, [dispatch]);

  const { MonthlylectureseriesData } = useSelector(
    (state) => state?.LearningEventsReducer
  );
  console.log("MonthlylectureseriesData", MonthlylectureseriesData);

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <div className="section-padding">
        <div className="container">
          <div className="bord bord-thin-bottom d-flex align-items-center">
            <h6 className="sub-title main-color mb-25">
              Monthly Lecture Series
            </h6>
          </div>

          <div className="gallery row stand-marg student-project-wrap">
            {MonthlylectureseriesData?.map((lecture) => (
              <div className="col-lg-3 col-md-6 items cat-1" key={lecture.id}>
                <div className="item mt-40">
                  <div className="img">
                    <a href="#">
                      <img
                        src={lecture.image}
                        alt=""
                        className="monthly-lecture-img"
                      />
                    </a>
                  </div>
                  <div className="cont mt-20 monthly-lecture-para lecture-description">
                    <p className="lecture-description">{lecture.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
};

export default Monthlylecture;
