import React, { useState, useEffect } from "react";
import antiragging from "../../../assets/imgs/icon copy/anti-theft-system.png";
import campusmonitoring from "../../../assets/imgs/icon copy/campusmonitoring.png";
import discipline from "../../../assets/imgs/icon copy/discipline.png";
import finance from "../../../assets/imgs/icon copy/finanacecommittee.png";
import internal from "../../../assets/imgs/icon copy/internalcomplaint.png";
import studentgrievance from "../../../assets/imgs/icon copy/studentgrievance.png";
import scst from "../../../assets/imgs/icon copy/sc/st.png";
import studentunion from "../../../assets/imgs/icon copy/studentunion.png";
import woman from "../../../assets/imgs/icon copy/woman.png";
import graduation from "../../../assets/imgs/submenu/graduation.jpg";
import { useDispatch, useSelector } from "react-redux";
import { StatutoryCommitteeActions } from "../../../Store/Statutory Committee/action";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";

function Statutorycommitte() {
  const [showCommittee, setShowCommittee] = useState(false);
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const routeNames = {
    "/statutory-committee": {
      menuName: "About Us",
      subMenuName: "Statutory Committee",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  // useEffect(() => {
  //   dispatch({ type: StatutoryCommitteeActions.GET_STATUTORY_COMMITTEE });
  // }, [dispatch]);

  const { StatutoryCommittee } = useSelector(
    (state) => state?.StatutoryCommitteeReducer
  );

  const handleClick = () => {
    setShowCommittee(true);
  };

  // Access the 'committee' array inside 'StatutoryCommittee'
  const committees = StatutoryCommittee?.committee || [];
  const committeeContent =
    StatutoryCommittee?.statutory_committee?.[0]?.committee_content || "";

  const committeeData = [
    {
      name: "Anti Ragging Committee",
      img: antiragging,
      pdf: committees.find((c) => c.committee_name === "Anti Ragging Committee")
        ?.pdf,
    },
    {
      name: "Internal Compliant Committee",
      img: internal,
      pdf: committees.find(
        (c) => c.committee_name === "Internal Compliant Committee"
      )?.pdf,
    },
    {
      name: "Sc/St Committee",
      img: scst,
      pdf: committees.find((c) => c.committee_name === "Sc/St Committee")?.pdf,
    },
    {
      name: "Students Grievance Redressal Committee",
      img: studentgrievance,
      pdf: committees.find(
        (c) => c.committee_name === "Students Grievance Redressal Committee"
      )?.pdf,
    },
    {
      name: "Discipline and Welfare Committee",
      img: discipline,
      pdf: committees.find(
        (c) => c.committee_name === "Discipline and Welfare Committee"
      )?.pdf,
    },
    {
      name: "Campus Monitoring and Flying Squad",
      img: campusmonitoring,
      pdf: committees.find(
        (c) => c.committee_name === "Campus Monitoring and Flying Squad"
      )?.pdf,
    },
    {
      name: "Women Empowerment Cell",
      img: woman,
      pdf: committees.find((c) => c.committee_name === "Women Empowerment Cell")
        ?.pdf,
    },
    {
      name: "Students Union Advisory/Committee",
      img: studentunion,
      pdf: committees.find(
        (c) => c.committee_name === "Students Union Advisory/Committee"
      )?.pdf,
    },
    {
      name: "Finance Committee",
      img: finance,
      pdf: committees.find((c) => c.committee_name === "Finance Committee")
        ?.pdf,
    },
  ];

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <div className="statutory">
        <div className="container statutorycommittee">
          <div className="mb-60">
            <h6 className="sub-title main-color mb-25">Our Committees</h6>
            <div className="bord pt-25 bord-thin-top d-flex align-items-center flex-md-wrap">
              <div className="statutorycommittee1">
                <h4 className="fw-600 text-u ls1 sct">
                  Institutional{" "}
                  <span className="fw-200 committee-text">Committees</span>
                </h4>
              </div>
              <div className="committee-center">
                <p className="committee-center-para">
                  <div className="committee-center-para1">
                    {committeeContent}
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-clr">
          <div className="container">
            <div className="buttons mb-60">
              {committeeData.map((committee) => (
                <button key={committee.name} className="neumorphic">
                  <img src={committee.img} alt={committee.name} />
                  <span>
                    {committee.pdf ? (
                      <a
                        href={committee.pdf}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {committee.name}
                      </a>
                    ) : (
                      committee.name
                    )}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Statutorycommitte;
