import { AlliedFacultyActions } from "./action";

const initialState = {
  AlliedFacultyData: [],
};

export const AlliedFacultyReducer = (state = initialState, action) => {
  switch (action.type) {
    case AlliedFacultyActions.SET_ALLIED_FACULTY:
      return {
        ...state,
        AlliedFacultyData: action.payload,
      };
    default:
      return state;
  }
};
