import React, { useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import StudentProjects from "../components/Student Portfolio/Student-Detailpage/index";
import Loading from "../common/Loading";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { HomePageActions } from "../Store/Home/action";

function StudentProjectListPage() {
  document.title = "StudentProjects";
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;

  const [loading, setLoading] = useState(true);

  // const backgroundColor = "black";
  const textColor = "black";

  useEffect(() => {
    window.scrollTo(0, 0);

    if (state && state.projectId) {
      dispatch({
        type: HomePageActions.STUDENT_PORTFOLIO_DETAILPAGE,
        payload: {
          projectId: state.projectId,
        },
      });
    } else {
    }
  }, [dispatch, state]);

  const { StudentPortfolioDetailpage } = useSelector(
    (state) => state.HomePageReducer
  );
  useEffect(() => {
    if (StudentPortfolioDetailpage?.images?.length !== 0) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [StudentPortfolioDetailpage])

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Header Color={textColor} isInGallery={true} />
          {/* <Header backgroundColor={backgroundColor} /> */}
          <StudentProjects />
          <Footer />
        </div>
      )}
    </div>
  );
}

export default StudentProjectListPage;
