import { PrincipalActions } from "./action";

const initialState = {
    Principalinfo: [],
};

export const PrincipalReducer = (state = initialState, action) => {
  switch (action.type) {
    case PrincipalActions.SET_ABOUTPRINCIPAL:
      return {
        ...state,
        Principalinfo: action.payload,
      };

    default:
      return state;
  }
};
