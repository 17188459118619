import { AdministrativeStaffsActions } from "./action";
import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";


export const AdministrativeStaffsSagas = function* () {
    yield all([
      takeEvery(
        AdministrativeStaffsActions.GET_ADMINISTRATIVE_STAFFS_CHIEF_ADVISOR,
        getAdministrativeStaffCheifAdvisor
      ),
      takeEvery(
        AdministrativeStaffsActions.GET_ADMINISTRATIVE_STAFFS_MENTORS_EXPERT_PANEL,
        getAdministrativeStaffMentorsExpertPanel
      ),
    ]);
  };
  

  const getAdministrativeStaffCheifAdvisor = function* (data) {
    try {
      const result = yield call(() => axios.get(`${API_URL}/administrative-chief-advisor`));
  
      yield put({
        type: AdministrativeStaffsActions.SET_ADMINISTRATIVE_STAFFS_CHIEF_ADVISOR,
        payload: result.data,
      });
    } catch (error) {
      console.error("Error", error);
    }
  };
  
  const getAdministrativeStaffMentorsExpertPanel = function* (data) {
    try {
      const result = yield call(() => axios.get(`${API_URL}/administrative-expert-member`));
  
      yield put({
        type: AdministrativeStaffsActions.SET_ADMINISTRATIVE_STAFFS_MENTORS_EXPERT_PANEL,
        payload: result.data,
      });
    } catch (error) {
      console.error("Error", error);
    }
  };
  
