import { SyllabusActions } from "./action";

const initialState = {
  SyllabusData: [],
};

export const SyllabusReducer = (state = initialState, action) => {
  switch (action.type) {
    case SyllabusActions.SET_SYLLABUS:
      return {
        ...state,
        SyllabusData: action.payload,
      };
    default:
      return state;
  }
};
