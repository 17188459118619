import { AdministrativeStaffsActions } from "./action";

const initialState = {
    AdministrativeChiefAdvisor: [],
    AdministrativeExpertmember: [],
  };
  
  export const AdministrativeStaffReducer = (state = initialState, action) => {
    switch (action.type) {
      case AdministrativeStaffsActions.SET_ADMINISTRATIVE_STAFFS_CHIEF_ADVISOR:
        return {
          ...state,
          AdministrativeChiefAdvisor: action.payload,
        };
      case AdministrativeStaffsActions.SET_ADMINISTRATIVE_STAFFS_MENTORS_EXPERT_PANEL:
        return {
          ...state,
          AdministrativeExpertmember: action.payload,
        };
  
      default:
        return state;
    }
  };