import React, { useState, useEffect } from "react";
import submenuservicon from "../../../assets/imgs/serv-icons/1.png";
import graduation from "../../../assets/imgs/submenu/graduation.jpg";
import { useDispatch, useSelector } from "react-redux";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";

function PsgLap() {
  const [activeTab, setActiveTab] = useState("tabs-1");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  const routeNames = {
    "/psg-iaq": { menuName: "About Us", subMenuName: "PSG Iaq" },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const dispatch = useDispatch();
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  // useEffect(() => {
  //   dispatch({ type: PSGIAQactions.GET_PSGIAQ_ABOUTUS_AND_SECTIONS });
  // }, [dispatch]);

  const { AboutusandSections } = useSelector((state) => state.psgiaq);
  const { psgiaq, sections } = AboutusandSections;

  return (
    <div id="smooth-content">
      <main className="main-bg blog-wrap">
        <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
        <section className="services-tab section-padding mb-80 abt-uss">
          <div className="container">
            <div className="cal-action pb-10 mb-40 bord-thin-bottom about-us-texts">
              <h6 className="sub-title mb-15 main-color ">About Us</h6>
            </div>
            <div className="sec-head mb-30"></div>
            <div className="text mb-50">
              <p className="para-psg">{psgiaq[0]?.content}</p>
            </div>
            <div className="row lg-marg" id="tabs">
              <div className="d-lg-none col-12">
                <div className="serv-tab-link tab-links full-width pt-40">
                  <div className="row justify-content-end">
                    <div className="col-lg-12">
                      <ul className="rest">
                        {sections.map((section, index) => (
                          <li
                            key={section.section.section_id}
                            // className={`item-link mb-15 ${
                            //   activeTab === `tabs-${index + 1}` ? "current" : ""
                            // }`}
                            className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                              activeTab === `tabs-${index + 1}` ? "current" : ""
                            }`}
                            onClick={() => handleTabClick(`tabs-${index + 1}`)}
                          >
                            <h5>
                              <span className="fz-18 opacity-7 mr-15">{`0${
                                index + 1
                              }`}</span>
                              {section.section.section_name}
                            </h5>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-none d-lg-block side-txt">
                <div className="serv-tab-link tab-links full-width pt-40">
                  <div className="row justify-content-end">
                    <div className="col-lg-12 iaq-padding">
                      <ul className="rest">
                        {sections.map((section, index) => (
                          <li
                            key={section.section.section_id}
                            className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                              activeTab === `tabs-${index + 1}` ? "current" : ""
                            }`}
                            
                            onClick={() => handleTabClick(`tabs-${index + 1}`)}
                          >
                            
                            <div className="u-line linee psg-iaq-tabs">
                              <span className="icon ti-arrow-circle-right tabss-psg "></span>
                              <span className="text fz-20 psg-margin">
                                {section.section.section_name}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-12">
                <div className="serv-tab-cont md-mb80 sticky-item">
                  {sections.map((section, index) => (
                    <div
                      key={section.section.section_id}
                      className={`tab-content ${
                        activeTab === `tabs-${index + 1}` ? "current" : ""
                      }`}
                      id={`tabs-${index + 1}`}
                    >
                      <div className="item">
                        <div className="sub-bg">
                          <div className="icon-img-60 mb-40">
                            <img src={submenuservicon} alt="" />
                          </div>
                          <div className="text">
                            <ul className="rest list-arrow mt-30 pt-30 bord-thin-top">
                              {section.contents.map((content, contentIndex) => (
                                <li key={contentIndex} className="pointtxt">
                                  {section.contents.length > 1 && (
                                    <span className="icon psgiap-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="2.2em"
                                        height="2.2em"
                                        viewBox="0 0 24 24"
                                      >
                                        <circle
                                          cx="12.1"
                                          cy="12.1"
                                          r="1"
                                          fill="none"
                                          stroke="#84e3c6"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                      </svg>
                                    </span>
                                  )}
                                  <p className="inline fw-400 fz-16 text ml-5 mt-2">
                                    {content.section_content}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default PsgLap;
