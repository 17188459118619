import { ConnectWithUsActions } from "./action";
import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const ConnectWithUsSagas = function* () {
  yield all([
    takeEvery(ConnectWithUsActions.GET_CONNEC_WITH_US, getConnectWithUs),
  ]);
};

const getConnectWithUs = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/connect_with_us`));

    yield put({
      type: ConnectWithUsActions.SET_CONNEC_WITH_US,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
