import { LearningEventsActions } from "./action";
import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const LearningEventSagas = function* () {
  yield all([
    takeEvery(
      LearningEventsActions.GET_MONTHLY_LECTURE_SERIES,
      getMonthlylectureseries
    ),
    takeEvery(LearningEventsActions.GET_STUDY_TOUR, getStudyTour),
    takeEvery(
      LearningEventsActions.GET_SITEVISITS_FIELDVISITS,
      getSiteVisitsAndFieldVisits
    ),
    takeEvery(LearningEventsActions.GET_NASA, getNASA),
    takeEvery(LearningEventsActions.GET_SYMPOSIUM, getSymposium),
  ]);
};

const getMonthlylectureseries = function* (data) {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/monthly-lecture-series`)
    );

    yield put({
      type: LearningEventsActions.SET_MONTHLY_LECTURE_SERIES,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
const getStudyTour = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/Study-Tour`));

    yield put({
      type: LearningEventsActions.SET_STUDY_TOUR,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};

const getSiteVisitsAndFieldVisits = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/Site-Field-Visits`));

    yield put({
      type: LearningEventsActions.SET_SITEVISITS_FIELDVISITS,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};

const getNASA = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/Nasa`));

    yield put({
      type: LearningEventsActions.SET_NASA,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};

const getSymposium = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/Symposium`));

    yield put({
      type: LearningEventsActions.SET_SYMPOSIUM,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
