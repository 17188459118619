import { TheTrustAction } from "./action";

const initialState = {
  TheTrustInfoandMsg: [],
};

export const TheTrustReducer = (state = initialState, action) => {
  switch (action.type) {
    case TheTrustAction.SET_THE_TRUST_INFO_MSG:
      return {
        ...state,
        TheTrustInfoandMsg: action.payload,
      };
    default:
      return state;
  }
};
