import React, { useState, useRef, useEffect } from "react";
// import Blogimg from "../../assets/imgs/blog/blog2.webp";
// import Blogimg4 from "../../assets/imgs/blog/blog4.webp";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../Store/Home/action";
import { useNavigate } from "react-router-dom";

function BlogListCrev() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_BLOG });
  }, [dispatch]);

  const { Blog } = useSelector((state) => state.HomePageReducer);

  const [animateHeading, setAnimateHeading] = useState(false);
  const headingRef = useRef(null);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!hasScrolledIntoView) {
            setAnimateHeading(true);
            setHasScrolledIntoView(true);
          }
        }
      });
    }, options);

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    // Cleanup
    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, [hasScrolledIntoView]);

  const handleOpenBlogDetail = (blogId) => {
    navigate("/Gallery", { state: { id: blogId, type: "blog" } });
  };

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    const dateObject = new Date(`${year}-${month}-${day}`);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return dateObject.toLocaleDateString("en-GB", options);
  };

  return (
    <section className="blog-list-crev section-padding">
      <div className="container blog-fin">
        <div className="sec-head mb-80">
          <h6 className="sub-title main-color mb-25">Our Blog</h6>
          <div className="bord pt-25 bord-thin-top d-flex align-items-center">
            <h2
              ref={headingRef}
              className={`fw-600  d-rotate wow ${
                animateHeading ? "heading-animation" : ""
              }`}
            >
              <span className="rotate-text blog-text princi">
                Read latest news - Archigazette
              </span>
            </h2>
          </div>
        </div>

        {/* First Item */}
        {Blog.slice(0, 3).map((item, index) => (
          <div
            className={`${animateHeading ? "heading-animation" : ""} ${
              index !== 1 ? "sub-bg2" : ""
            }`}
            key={index}
          >
            <div className="item  wow fadeInUp " data-wow-delay=".1s">
              <div className="row">
                <div className="col-lg-3">
                  <div className="info md-mb30">
                    <div className="d-flex align-items-center">
                      <div className="author-info">
                        <span className="fz-13 opacity-8">Posted by</span>
                        <h6 className="fz-18 blo-text-na">Name</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="cont">
                    <h5 className="mb-10 underline blo-text-na">
                      <a href="#">{item.description.slice(0, 150)}...</a>
                    </h5>
                  </div>
                </div>
                <div className="col-lg-3 d-flex align-items-center justify-end">
                  <div className="ml-auto">
                    <span className="date sub-title fz-13 opacity-8 mb-30">
                      {formatDate(item.date)}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="background bg-img valign text-center"
                style={{ backgroundImage: `url(${item.thumbnail})` }}
                data-overlay-dark="4"
              >
                <div className="more ontop full-width">
                  <a onClick={() => handleOpenBlogDetail(item.id)}>
                    <span>
                      Read More <i className="fas fa-arrow-right ml-10"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="flr mt-30">
          <a href="#" className="go-more">
            <span className="text">View more </span>
            <span className="icon ti-arrow-circle-right"></span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default BlogListCrev;
