import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";
import { SonsandCharitiesActions } from "./action";

export const SonsandCharitiesSagas = function* () {
  yield all([
    takeEvery(
      SonsandCharitiesActions.GET_SONSANDCHARITIES_SECTION,
      getSonsandCharitiesSections
    ),
  ]);
};

const getSonsandCharitiesSections = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/SonsandCharities`));

    yield put({
      type: SonsandCharitiesActions.SET_SONSANDCHARITIES_SECTION,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
