import { StatutoryCommitteeActions } from "./action";

const initialState = {
  StatutoryCommittee: [],
};

export const StatutoryCommitteeReducer = (state = initialState, action) => {
  switch (action.type) {
    case StatutoryCommitteeActions.SET_STATUTORY_COMMITTEE:
      return {
        ...state,
        StatutoryCommittee: action.payload,
      };
    default:
      return state;
  }
};
