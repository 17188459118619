import { LifeAtCampusActions } from "./action";

const initialState = {
  LifeAtCampusData: [],
};

export const LifeAtCampusReducer = (state = initialState, action) => {
  switch (action.type) {
    case LifeAtCampusActions.SET_LIFE_AT_CAMPUS:
      return {
        ...state,
        LifeAtCampusData: action.payload,
      };
    default:
      return state;
  }
};
