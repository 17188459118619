import { SyllabusActions } from "./action";
import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const SyllabusSagas = function* () {
  yield all([takeEvery(SyllabusActions.GET_SYLLABUS, getSyllabusInfo)]);
};

const getSyllabusInfo = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/syllabus`));

    yield put({
      type: SyllabusActions.SET_SYLLABUS,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
