import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";
import { AdministrationAction } from "./action";

export const AdministrationSagas = function* () {
  yield all([
    takeEvery(
      AdministrationAction.GET_GOVERNINGCOUNCILMEMBERS,
      getGoverningCouncil
    ),
  ]);
  yield all([
    takeEvery(
      AdministrationAction.GET_ADMINISTRATION_SECRETARYMESSAGE,
      getSecretaryInfo
    ),
  ]);
  yield all([
    takeEvery(
      AdministrationAction.GET_ADMINISTRATION_ORGANIZATION,
      getOrganizationSchedule
    ),
  ]);
};

const getGoverningCouncil = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/Councilmembers`));

    yield put({
      type: AdministrationAction.SET_GOVERNINGCOUNCILMEMBERS,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};

const getSecretaryInfo = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/Secreateryinfo`));

    yield put({
      type: AdministrationAction.SET_ADMINISTRATION_SECRETARYMESSAGE,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};

const getOrganizationSchedule = function* (data) {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/Organizational_schedule`)
    );

    yield put({
      type: AdministrationAction.SET_ADMINISTRATION_ORGANIZATION,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
