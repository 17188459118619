import { AlliedFacultyActions } from "./action";
import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const AlliedFacultySagas = function* () {
  yield all([
    takeEvery(AlliedFacultyActions.GET_ALLIED_FACULTY, geAlliedFaculty),
  ]);
};

const geAlliedFaculty = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/allied-faculty`));

    yield put({
      type: AlliedFacultyActions.SET_ALLIED_FACULTY,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
