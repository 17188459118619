import { ConnectWithUsActions } from "./action";

const initialState = {
  ConnectWithUsData: [],
};

export const ConnectWithUsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ConnectWithUsActions.SET_CONNEC_WITH_US:
      return {
        ...state,
        ConnectWithUsData: action.payload,
      };
    default:
      return state;
  }
};
