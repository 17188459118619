import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SideNavigation from "../Menu-sidenavbar/SideNavigation ";
import graduation from "../../assets/imgs/submenu/graduation.jpg";
import { WorkWithUsActions } from "../../Store/Work with us/action";
import { useDispatch, useSelector } from "react-redux";
import HeaderBackground from "../../common/SubpagesBGimage";

function WorkWithUss() {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState("tab-1");
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const [animationTriggered, setAnimationTriggered] = useState(false);

  const routeNames = {
    "/work-with-us": { menuName: "Work with us" },
  };

  const { menuName } = location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const handleMouseEnter = (tabId) => {
    setCurrentTab(tabId);
  };

  const handleTabClick = (index) => {
    setActiveAccordion((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    dispatch({ type: WorkWithUsActions.GET_WORK_WITH_US });
    setAnimationTriggered(true);
  }, [dispatch]);

  const { WorkwithusData } = useSelector((state) => state?.WorkWithUsReducer);

  // Ensure WorkwithusData and work-with-us exist
  const workWithUsArray = WorkwithusData?.["work-with-us"] || [];
  const currentTabData = workWithUsArray.find(
    (tab) => tab.id === parseInt(currentTab.split("-")[1])
  );

  return (
    <div className="workwithus-container">
      <HeaderBackground
        menuName={menuName}
        handleMenuToggle={handleMenuToggle}
      />
      <section className="portfolio-tab crev section-padding">
        <div className="container position-re">
          <div className="sec-head mb-80">
            <div className="bord pt-25 bord-thin-top d-flex align-items-center">
              <h4 className="fw-600 text-u ls1 pl-30">
                Work with us <span className="fw-200"></span>
              </h4>
            </div>
            <div className="program-description">
              <p className="pl-50 res-work-des">
                {WorkwithusData?.description?.[0]?.work ||
                  "No description available."}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="left">
                <div className="glry-img">
                  <div className="bg-img tab-img current">
                    <img
                      src={currentTabData?.image || ""}
                      alt=""
                      className="programs-img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 content">
              {workWithUsArray.map((item, index) => (
                <div
                  key={`tab-${item.id}`}
                  className={`mb-50 ${
                    currentTab === `tab-${item.id}` ? "current" : ""
                  }`}
                  data-tab={`tab-${item.id}`}
                  onMouseEnter={() => handleMouseEnter(`tab-${item.id}`)}
                  onClick={() => handleTabClick(index)}
                  style={{
                    visibility: animationTriggered ? "visible" : "hidden",
                    animationName: "fadeInUp",
                    animationDelay: `${index * 0.3}s`,
                    animationDuration: "3s",
                    animationFillMode: "both",
                  }}
                >
                  <div className="accordion bord">
                    <div className="item mb-15 wow fadeInUp res-work">
                      <div className="title">
                        <h6 className="text-u programs-texts">{item.name}</h6>
                        <span className="icoo">
                          {activeAccordion === index ? "×" : "+"}
                        </span>
                      </div>
                      {activeAccordion === index && (
                        <div>
                          <p className="accorion-infoo bord-thin-lefts">
                            {item.content}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default WorkWithUss;
