export const AdministrativeStaffsActions = {
    GET_ADMINISTRATIVE_STAFFS_CHIEF_ADVISOR: "GET_ADMINISTRATIVE_STAFFS_CHIEF_ADVISOR",
    SET_ADMINISTRATIVE_STAFFS_CHIEF_ADVISOR: "SET_ADMINISTRATIVE_STAFFS_CHIEF_ADVISOR",
  

    GET_ADMINISTRATIVE_STAFFS_MENTORS_EXPERT_PANEL :"GET_ADMINISTRATIVE_STAFFS_MENTORS_EXPERT_PANEL",
    SET_ADMINISTRATIVE_STAFFS_MENTORS_EXPERT_PANEL :"SET_ADMINISTRATIVE_STAFFS_MENTORS_EXPERT_PANEL"

};

