import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import FactsandFigures from "../components/Submenu/FactsandFigures/FactsandFigures";
import Footer from "../components/Layout/Footer";
import SubmenuHeader from "../components/Submenu/SubmenuCommonHeader/SubmenuHeader";
import Cursor from "../common/Cursor";
import Loading from "../common/Loading";
import { useSelector, useDispatch } from 'react-redux'
import { FactsandFiguresActions } from "../Store/FactsandFigures/action";
import gsap from 'gsap';
import { PageOpeningAnimation } from "../common/pageOpeningAnimation";

function Factsandfigures() {
  document.title = "Facts-and-figures-PSG-IAP";
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    dispatch({ type: FactsandFiguresActions.GET_FACTSANDFIGURES_SECTIONS });
  }, []);

  const { factsandfigures } = useSelector((state) => state?.FactsandFiguresReducer);

  useEffect(() => {
    if (factsandfigures?.length !== 0) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [factsandfigures])

  //for page opening animations
  useEffect(() => {
    if (!loading) {
      const svg = document.getElementById("svg");
      const tl = gsap.timeline({ repeat: 0, yoyo: true });

      tl.to('.bluee', {
        duration: 1,
        attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
        ease: 'power2.in',
      }).to('.bluee', {
        duration: 1,
        attr: { d: 'M0 2S175 1 500 1s500 1 500 1V0H0Z' },
        ease: 'power2.out',
      });

      // Start SVG animation after the loading text is hidden
      tl.add(() => {
        gsap.to(svg, {
          duration: 0.8,
          attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
          ease: 'power2.in',
        });
      });

      // Clean up animation on unmount
      return () => {
        tl.kill(); // Kill the animation to prevent memory leaks
      };
    }
  }, [loading]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {/* <Cursor /> */}
          <PageOpeningAnimation />
          <Header />
          <FactsandFigures />
          <Footer />
        </>
      )}
    </div>
  );
}

export default Factsandfigures;
