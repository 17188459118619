import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";
import { FooterActions } from "./action";

export const FooterSagas = function* () {
  yield all([takeEvery(FooterActions.GET_FOOTER_DETAILS, getFooterdata)]);
};

const getFooterdata = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/footer`));

    yield put({
      type: FooterActions.SET_FOOTER_DETAILS,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
