import React, { useState, useEffect } from "react";
import img1 from "../../assets/imgs/serv-icons/1.png";
import graduation from "../../assets/imgs/submenu/graduation.jpg";
import collegecode from "../../assets/imgs/serv-icons/college-code.png";
import criteria from "../../assets/imgs/serv-icons/criteria.png";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AdmissionsActions } from "../../Store/Admissions/action";
import SideNavigation from "../Menu-sidenavbar/SideNavigation ";
import HeaderBackground from "../../common/SubpagesBGimage";

function Admissions() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("tabs-1");
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);

  const routeNames = {
    "/admissions": {
      menuName: "Admissions",
    },
  };

  const { menuName } = location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    dispatch({ type: AdmissionsActions.GET_ADMISSIONS });
  }, [dispatch]);

  const { AdmissionsData } = useSelector((state) => state?.AdmissionsReducer);

  const handleTabClick = (tabs) => {
    setActiveTab(tabs);
  };

  const renderContent = () => {
    const currentSection = AdmissionsData?.admission?.find(
      (item, index) => `tabs-${index + 1}` === activeTab
    );

    if (currentSection) {
      const { description } = currentSection;

      if (description?.length > 1) {
        return (
          <div className="tab-content current" id={activeTab}>
            <div className="item">
              <div className="sub-bg">
                <div className="icon-img-60 mb-40">
                  <img
                    src={
                      activeTab === "tabs-1"
                        ? collegecode
                        : activeTab === "tabs-2"
                        ? criteria
                        : img1
                    }
                    alt=""
                  />
                </div>
                <div className="text">
                  <ul className="rest list-arrow mt-30 bord-thin-top">
                    <h6 className="Components-title">
                      {currentSection.Admission?.section_name}
                    </h6>
                    {description.map((desc, i) => (
                      <li key={i}>
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2.2em"
                            height="2.2em"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              cx="12.1"
                              cy="12.1"
                              r="1"
                              fill="none"
                              stroke="#84e3c6"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </svg>
                        </span>
                        <h6 className="inline fw-400 fz-16 text">
                          {desc.Admission}
                        </h6>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (description?.length === 1) {
        return (
          <div className="tab-content current" id={activeTab}>
            <div className="item">
              <div className="sub-bg">
                <div className="icon-img-60 mb-40">
                  <img src={img1} alt="" />
                </div>
                <div className="text">
                  <div className="text bord-thin-top achieve-pd">
                    <h6 className="Components-title">
                      {currentSection.Admission?.section_name}
                    </h6>
                    <p>{description[0]?.Admission}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return null;
  };

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        handleMenuToggle={handleMenuToggle}
      />
      <section className="services-tab section-padding mb-80">
        <div className="container">
          <div className="cal-action pb-10 mb-40 bord-thin-bottom">
            <h6 className="sub-title mb-15 main-color">ADMISSIONS</h6>
          </div>
          <div className="row lg-marg" id="tabs">
            <div className="d-lg-none col-12">
              <div className="serv-tab-link tab-links full-width pt-40">
                <div className="row justify-content-end">
                  <div className="col-lg-12">
                    <ul className="rest">
                      {AdmissionsData?.admission?.map((item, index) => (
                        <li
                          key={index}
                          className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                            activeTab === `tabs-${index + 1}` ? "current" : ""
                          }`}
                          data-tab={`tabs-${index + 1}`}
                          onClick={() => handleTabClick(`tabs-${index + 1}`)}
                        >
                          <h5>
                            <span className="fz-18 opacity-7 mr-15">{`0${
                              index + 1
                            }`}</span>
                            {item?.Admission?.section_name}
                          </h5>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-none d-lg-block">
              <div className="serv-tab-link tab-links full-width pt-40">
                <div className="row justify-content-end">
                  <div className="col-lg-12">
                    <ul className="rest">
                      {AdmissionsData?.admission?.map((item, index) => (
                        <li
                          key={index}
                          className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                            activeTab === `tabs-${index + 1}` ? "current" : ""
                          }`}
                          onClick={() => handleTabClick(`tabs-${index + 1}`)}
                        >
                          <div className="u-line linee">
                            <span className="icon ti-arrow-circle-right"></span>
                            <span className="text fz-20 psg-margin">
                              {item?.Admission?.section_name}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-12">
              <div className="md-mb80">{renderContent()}</div>
            </div>
          </div>
        </div>
      </section>

      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Admissions;
