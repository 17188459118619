import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import graduation from "../../assets/imgs/submenu/graduation.jpg";
import SideNavigation from "../../components/Menu-sidenavbar/SideNavigation ";
import { ConnectWithUsActions } from "../../Store/Connect With Us/action";
import HeaderBackground from "../../common/SubpagesBGimage";

const ConnectWithus = () => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  useEffect(() => {
    dispatch({ type: ConnectWithUsActions.GET_CONNEC_WITH_US });
  }, [dispatch]);

  const { ConnectWithUsData } = useSelector(
    (state) => state?.ConnectWithUsReducer
  );
  const contact = ConnectWithUsData?.contact?.[0];
  const socialLinks = ConnectWithUsData?.Social_links?.[0];

  const routeNames = {
    "/ConnectWithus": {
      menuName: "Connect with us",
      subMenuName: "",
    },
  };

  const { menuName } = location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  return (
    <div>
      <main className="main-bg blog-wrap">
        <HeaderBackground
          menuName={menuName}
          handleMenuToggle={handleMenuToggle}
        />

        <section className="contact section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 valign">
                <div className="sec-head info-box full-width md-mb80">
                  <div className="phone fz-30 fw-600 connect-with-us main-color">
                    <a href={`tel:${contact?.contact_no_1}`}>
                      {contact?.contact_no_1}
                    </a>
                  </div>
                  {contact?.contact_no_2 && (
                    <div className="phone fz-30 fw-600 connect-with-us main-color">
                      <a href={`tel:${contact?.contact_no_2}`}>
                        {contact?.contact_no_2}
                      </a>
                    </div>
                  )}
                  <div className="morinfo mt-50 pb-30 bord-thin-bottom">
                    <h6 className="mb-15">Address</h6>
                    <p>{contact?.address}</p>
                  </div>
                  <div className="morinfo mt-30 pb-30 bord-thin-bottom">
                    <h6 className="mb-15">Email</h6>
                    <p>{contact?.mail}</p>
                  </div>
                  <div className="social-icon-circle mt-50">
                    {socialLinks?.facebook && (
                      <a
                        href={socialLinks.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    )}
                    {socialLinks?.instagram && (
                      <a
                        href={socialLinks.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    )}
                    {socialLinks?.twitter && (
                      <a
                        href={socialLinks.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    )}
                    {/* {socialLinks?.linkedin && (
                      <a
                        href={socialLinks.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-linkedin"></i>
                        </a>   
                    )} */}
                    {/* {socialLinks?.dribbble && (
                      <a
                        href={socialLinks.dribbble}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-dribbble"></i>
                      </a>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="col-lg-7 offset-lg-1 valign">
                <div className="full-width">
                  <div className="sec-head mb-50">
                    <h6 className="sub-title main-color mb-15">Location</h6>
                  </div>
                  <div className="google-map">
                    <iframe
                      id="gmap_canvas"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7831.285429470795!2d77.092779!3d11.065391!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8f8a655555555%3A0xa3da0cb94e76285c!2sPSG%20Institute%20of%20Technology%20and%20Applied%20Research%20(PSG%20iTech)!5e0!3m2!1sen!2sin!4v1723209161324!5m2!1sen!2sin"
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      style={{ width: "100%", height: "450px", border: "0" }}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
};

export default ConnectWithus;
