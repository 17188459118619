import { DesignchairActions } from "./action";import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const DesignchairSagas = function* () {
    yield all([
      takeEvery(
        DesignchairActions.GET_DESIGNCHAIR_SECTION_DETAILS,
        getDesignchairsection
      ),
    ]);
  };
  
  const getDesignchairsection = function* (data) {
    try {
      const result = yield call(() => axios.get(`${API_URL}/design-chair`));
  
      yield put({
        type: DesignchairActions.SET_DESIGNCHAIR_SECTION_DETAILS,
        payload: result.data,
      });
    } catch (error) {
      console.error("Error", error);
    }
  };