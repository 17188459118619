import React from 'react'
export const PageOpeningAnimation = () => {
    return (
        <div className="loader-wrap">
            <svg id="svg" viewBox="0 0 1000 1000" preserveAspectRatio="none">
                <path
                    className="bluee"
                    d="M0,1005S175,995,500,995s500,5,500,5V0H0Z"
                ></path>
            </svg>
        </div>
    )
}