import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useDispatch, useSelector } from "react-redux";
import { LearningEventsActions } from "../../../Store/Learning Events/action";
import graduation from "../../../assets/imgs/submenu/graduation.jpg";
import HeaderBackground from "../../../common/SubpagesBGimage";

const SiteVistsFieldEvents = () => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const routeNames = {
    "/site-visits&field-visits": {
      menuName: "Learning Events",
      subMenuName: "Site Visits & Field Visits",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};
  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    dispatch({ type: LearningEventsActions.GET_SITEVISITS_FIELDVISITS });
  }, [dispatch]);

  const { SiteVisitsAndFieldVisitsData } = useSelector(
    (state) => state?.LearningEventsReducer
  );

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <div className="section-padding">
        <div className="container">
          <div className="bord bord-thin-bottom d-flex align-items-center">
            <h6 className="sub-title main-color mb-25">
              Site Visits & Field Visits
            </h6>
          </div>

          <div className="gallery row stand-marg student-project-wrap">
            {SiteVisitsAndFieldVisitsData?.map((visit, index) => (
              <div className="col-lg-3 col-md-6 items cat-1" key={visit.id}>
                <div className="item mt-40">
                  <div className="img">
                    <a href="#">
                      <img
                        src={visit.image}
                        alt={`Site Visit ${index + 1}`}
                        className="monthly-lecture-img"
                      />
                    </a>
                  </div>
                  <div className="cont mt-20 monthly-lecture-para">
                    <p className="lecture-description">{visit.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
};

export default SiteVistsFieldEvents;
