import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import NewsandEvents from "../components/News&Events/NewsandEvents";
import VideoSection from "../components/Latest Video/VideoSection";
import Testimonials from "../components/Testimonial/Testimonials";
import ImageComponent from "../components/Background Image/BgImage";
import PortfolioTab from "../components/Student Portfolio/PortfolioTab";
import AboutPrincipal from "../components/Principal/AboutPrincipal";
import BlogListCrev from "../components/Blog/Blog";
import Footer from "../components/Layout/Footer";
import Loading from "../common/Loading";
import BannerVideo from "../common/BannerVideo";
import Cursor from "../common/Cursor";
import PsgLap from "../components/Submenu/Psg-iap/PsgLap";
import TheTrust from "../components/Submenu/Trust/TheTrust";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../Store/Home/action";
import gsap from 'gsap';
import { PageOpeningAnimation } from '../common/pageOpeningAnimation'

function Home() {
  document.title = "PSG-IAP";
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true);
  const { Banner } = useSelector((state) => state.HomePageReducer);

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_HEADER_BANNER });
  }, [dispatch]);

  useEffect(() => {
    if (Banner?.length !== 0) {
      setLoading(false)
    }
  }, [Banner]);

  //for page opening animations
  useEffect(() => {
    if (!loading) {
      const svg = document.getElementById("svg");
      const tl = gsap.timeline({ repeat: 0, yoyo: true });

      tl.to('.bluee', {
        duration: 1,
        attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
        ease: 'power2.in',
      }).to('.bluee', {
        duration: 1,
        attr: { d: 'M0 2S175 1 500 1s500 1 500 1V0H0Z' },
        ease: 'power2.out',
      });

      // Start SVG animation after the loading text is hidden
      tl.add(() => {
        gsap.to(svg, {
          duration: 0.8,
          attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
          ease: 'power2.in',
        });
      });

      // Clean up animation on unmount
      return () => {
        tl.kill(); // Kill the animation to prevent memory leaks
      };
    }
  }, [loading]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <PageOpeningAnimation />
          <Header />
          <BannerVideo />
          <NewsandEvents />
          <VideoSection />
          <Testimonials />
          <ImageComponent />
          <PortfolioTab />
          <AboutPrincipal />
          <BlogListCrev />
          <Footer />
          {/* <PsgLap/>
          <TheTrust/>
*/}
        </>
      )}
    </div>
  );
}

export default Home;
