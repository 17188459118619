import React,{useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../Store/Home/action";
const ImageComponent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_BACKGROUNDIMAGE });
  }, [dispatch]);

  const { BackgroundImage } = useSelector((state) => state.HomePageReducer);
  
  return (
    <div className="parallax-container">
      {BackgroundImage?.map((item,index)=>
      <div className="parallax-image" key={index}  
      style={{ backgroundImage: 
      `url(${item.image})`
       }} />
      )}

      <div className="content"></div>
    </div>
  );
};
export default ImageComponent;
