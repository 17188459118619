export const LearningEventsActions = {
  GET_MONTHLY_LECTURE_SERIES: "GET_MONTHLY_LECTURE_SERIES",
  SET_MONTHLY_LECTURE_SERIES: "SET_MONTHLY_LECTURE_SERIES",

  GET_STUDY_TOUR: "GET_STUDY_TOUR",
  SET_STUDY_TOUR: "SET_STUDY_TOUR",

  GET_SITEVISITS_FIELDVISITS: "GET_SITEVISITS_FIELDVISITS",
  SET_SITEVISITS_FIELDVISITS: "SET_SITEVISITS_FIELDVISITS",

  GET_NASA: "GET_NASA",
  SET_NASA: "SET_NASA",

  GET_SYMPOSIUM: "GET_SYMPOSIUM",
  SET_SYMPOSIUM: "SET_SYMPOSIUM",
};
