import {IQACActions} from "./action"

const initialState = {
    IQACdata: [],
  };
  
  export const iqacReducer = (state = initialState, action) => {
    switch (action.type) {
      case IQACActions.SET_IQAC:
        return {
          ...state,
          IQACdata: action.payload,
        };
      default:
        return state;
    }
  };