import { useEffect } from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
import {  useSelector } from "react-redux";
// import { HomePageActions } from "../../../Store/Home/action";
// import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";

const StudentProjects = () => {
  // const location = useLocation();
  // const dispatch = useDispatch();
  // const { state } = location;
  // const navigate = useNavigate();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
    
  //   if (state && state.projectId) {
  //     dispatch({
  //       type: HomePageActions.STUDENT_PORTFOLIO_DETAILPAGE,
  //       payload: {
  //         projectId: state.projectId,
  //       },
  //     });
  //   } else {
  //   }
  // }, [dispatch, state]);

  useEffect(() => {
    Fancybox.bind('[data-fancybox="gallery"]');
  }, []);

  const { StudentPortfolioDetailpage } = useSelector(
    (state) => state.HomePageReducer
  );

  return (
    <Container>
      {StudentPortfolioDetailpage && (
        <section className="main-grid">
          <div className="gallery-head">
            <h1 className="text-center h1 pb-40 gallery-heading">
              {StudentPortfolioDetailpage?.projectname}
            </h1>
            <p className="mb-30 ml-20 mr-15 gallery-text">
              {StudentPortfolioDetailpage?.description}
            </p>
          </div>
          <div className="container">
            {StudentPortfolioDetailpage?.images &&
              StudentPortfolioDetailpage.images.map((item, index) => (
                <div className="card" key={index}>
                  <div className="card-image">
                    <a
                      href={item.image}
                      data-fancybox="gallery"
                      data-caption={item.content}
                    >
                      <img src={item.image} alt="Img Gallery" />
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </section>
      )}
    </Container>
  

  );
};

export default StudentProjects;
