import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";
import { PSGIAQactions } from "./action";

export const psgiaqSagas = function* () {
  yield all([
    takeEvery(
      PSGIAQactions.GET_PSGIAQ_ABOUTUS_AND_SECTIONS,
      getPsgiaqAboutusandSections
    ),
  ]);
};

const getPsgiaqAboutusandSections = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/psg_iaq`));

    yield put({
      type: PSGIAQactions.SET_PSGIAQ_ABOUTUS_AND_SECTIONS,
      payload: result.data,
    });

  } catch (error) {
    console.error("Error", error);
  }
};
