import { HomePageActions } from "./action";

const initialState = {
  HeaderScrollText: [],
  MenusAndSubmenus: [],
  Banner: [],
  BackgroundImage: [],
  Testimonial: [],
  AboutPrincipal: [],
  LatestVideo: [],
  Blog: [],
  StudentPortfolio: [],
  NewsAndEvents: [],
  DetailPage: null,
  StudentPortfolioDetailpage: [],
  ListpageBGImage: [],
};

export const HomePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case HomePageActions.SET_HOME_TOPBAR_SCROLL_TEXT:
      return {
        ...state,
        HeaderScrollText: action.payload,
      };
    case HomePageActions.SET_HOME_MENUS_AND_SUBMENUS:
      return {
        ...state,
        MenusAndSubmenus: action.payload,
      };
    case HomePageActions.SET_HOME_HEADER_BANNER:
      return {
        ...state,
        Banner: action.payload,
      };
    case HomePageActions.SET_HOME_BACKGROUNDIMAGE:
      return {
        ...state,
        BackgroundImage: action.payload,
      };
    case HomePageActions.SET_HOME_TESTIMONIAL:
      return {
        ...state,
        Testimonial: action.payload,
      };

    case HomePageActions.SET_HOME_ABOUTPRINCIPAL:
      return {
        ...state,
        AboutPrincipal: action.payload,
      };
    case HomePageActions.SET_HOME_LATESTVIDEO:
      return {
        ...state,
        LatestVideo: action.payload,
      };
    case HomePageActions.SET_HOME_BLOG: {
      return {
        ...state,
        Blog: action.payload,
      };
    }
    case HomePageActions.SET_HOME_STUDENTPORTFOLIO: {
      return {
        ...state,
        StudentPortfolio: action.payload,
      };
    }
    case HomePageActions.SET_HOME_NEWS_AND_EVENTS: {
      return {
        ...state,
        NewsAndEvents: action.payload,
      };
    }

    case HomePageActions.SET_DETAILPAGE: {
      return {
        ...state,
        DetailPage: action.payload,
      };
    }

    case HomePageActions.SET_STUDENT_PORTFOLIO_DETAILPAGE: {
      return {
        ...state,
        StudentPortfolioDetailpage: action.payload,
      };
    }

    case HomePageActions.SET_LISTPAGE_BACKGROUND_IMAGE: {
      return {
        ...state,
        ListpageBGImage: action.payload,
      };
    }

    default:
      return state;
  }
};
