import { AdmissionsActions } from "./action";


const initialState = {
    AdmissionsData: [],
  };
  
  export const AdmissionsReducer = (state = initialState, action) => {
    switch (action.type) {
      case AdmissionsActions.SET_ADMISSIONS:
        return {
          ...state,
          AdmissionsData: action.payload,
        };
      default:
        return state;
    }
  };