import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import AdministrativeStaff from "../components/Submenu/AdminstrativeStaff/AdminstrativeStaff";
import Footer from "../components/Layout/Footer";
import Cursor from "../common/Cursor";
import Loading from "../common/Loading";
import { useDispatch, useSelector } from 'react-redux'
import { AdministrativeStaffsActions } from "../Store/Administrative Staffs/action";
import { PageOpeningAnimation } from "../common/pageOpeningAnimation";
import gsap from 'gsap';

function Administrativestaff() {
  document.title = "Administrative Staff";
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch({
      type: AdministrativeStaffsActions.GET_ADMINISTRATIVE_STAFFS_CHIEF_ADVISOR,
    });
  }, [])

  const { AdministrativeChiefAdvisor } = useSelector(
    (state) => state?.AdministrativeStaffReducer
  );

  useEffect(() => {
    if (AdministrativeChiefAdvisor?.length !== 0) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [AdministrativeChiefAdvisor])

  //for page opening animations
  useEffect(() => {
    if (!loading) {
      const svg = document.getElementById("svg");
      const tl = gsap.timeline({ repeat: 0, yoyo: true });

      tl.to('.bluee', {
        duration: 1,
        attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
        ease: 'power2.in',
      }).to('.bluee', {
        duration: 1,
        attr: { d: 'M0 2S175 1 500 1s500 1 500 1V0H0Z' },
        ease: 'power2.out',
      });

      // Start SVG animation after the loading text is hidden
      tl.add(() => {
        gsap.to(svg, {
          duration: 0.8,
          attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
          ease: 'power2.in',
        });
      });

      // Clean up animation on unmount
      return () => {
        tl.kill(); // Kill the animation to prevent memory leaks
      };
    }
  }, [loading]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {/* <Cursor /> */}
          <PageOpeningAnimation />
          <Header />
          <AdministrativeStaff />
          <Footer />
        </>
      )}
    </div>
  );
}

export default Administrativestaff;
