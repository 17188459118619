import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import Gallery from "../components/News&Events/DetailPageNews&Events/index";
import Footer from "../components/Layout/Footer";
// import Cursor from "../common/Cursor";
import Loading from "../common/Loading";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../Store/Home/action";

function GalleryPage() {
  document.title = "Gallery";

  const textColor = "black";
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (state?.id) {
      const payload = {
        id: state.id,
      };
      dispatch({
        type: HomePageActions.GET_DETAILPAGE,
        payload: payload,
      });
    }
  }, [dispatch, state?.id]);

  const { DetailPage } = useSelector((state) => state.HomePageReducer);

  useEffect(() => {
    if (DetailPage?.images?.length !== 0) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [DetailPage])

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {/* <Cursor /> */}
          <Header Color={textColor} isInGallery={true} />
          <Gallery />
          <Footer />
        </div>
      )}
    </div>
  );
}

export default GalleryPage;
