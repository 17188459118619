import { FactsandFiguresActions } from "./action";

const initialState = {
  factsandfigures: [],
};

export const FactsandFiguresReducer = (state = initialState, action) => {
  switch (action.type) {
    case FactsandFiguresActions.SET_FACTSANDFIGURES_SECTIONS:
      return {
        ...state,
        factsandfigures: action.payload,
      };
    default:
      return state;
  }
};
