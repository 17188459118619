export const ExpertPanelMembersActions = {
    GET_EXPERTPANEL_MEMBERS_CHIEF_ADVISOR: "GET_EXPERTPANEL_MEMBERS_CHIEF_ADVISOR",
    SET_EXPERTPANEL_MEMBERS_CHIEF_ADVISOR: "SET_EXPERTPANEL_MEMBERS_CHIEF_ADVISOR",
  

    GET_EXPERTPANEL_MEMBERS_MENTORS_EXPERT_PANEL :"GET_EXPERTPANEL_MEMBERS_MENTORS_EXPERT_PANEL",
    SET_EXPERTPANEL_MEMBERS_MENTORS_EXPERT_PANEL :"SET_EXPERTPANEL_MEMBERS_MENTORS_EXPERT_PANEL"

};
  
 

  