import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";
import { PrincipalActions } from "./action";

export const PrincipalSagas = function* () {
  yield all([
    takeEvery(PrincipalActions.GET_ABOUTPRINCIPAL, getAboutPrincipal),
  ]);
};

const getAboutPrincipal = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/principal-message`));

    yield put({
      type: PrincipalActions.SET_ABOUTPRINCIPAL,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
